<template>
    <div
        class="loader-wrapper"
        :class="{ 'is-page': page }"
    >
        <div
            class="cookbook-loader"
        >
            <Icon
                icon="utensils"
                :style="iconStyle"
            />
        </div>
    </div>
</template>

<script>
    import Icon from "@app/components/Common/Icon.vue";

    export default {
        components: {
            Icon
        },
        props: {
            size: {
                type: Number,
                required: false,
                default: 10
            },
            page: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        setup(props) {
            const iconStyle = `font-size: ${props.size}em;`;

            return {
                iconStyle
            }
        }
    }

</script>

<style scoped>
    .loader-wrapper {
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
    }

    .loader-wrapper.is-page {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #fff;
    }

    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 75 }
    }

    .cookbook-loader {
        animation: fadeIn 1s infinite alternate;
    }

</style>
