<template>
    <a
        :href="clickHref"
        v-on="onClick ? {'click': click} : {}"
    >
        <div class="card">
            <div class="card-image">
                <figure class="image">
                    <img
                        v-if="recipe.featureImageUrl"
                        :src="recipe.featureImageUrl"
                        alt="feature image"
                    >
                    <div
                        v-else
                        class="has-text-centered"
                    >
                        <Icon
                            icon="utensils"
                            style="font-size: 15rem;"
                        />
                    </div>
                </figure>
            </div>
            <div class="card-content ">
                <p class="title is-5 has-text-centered">
                    {{ recipe.name }}
                </p>
                <div class="is-flex is-flex-grow-1 is-justify-content-center">
                    <Rating
                        :model-value="recipe.rating"
                        :readonly="true"
                    />
                </div>

            </div>
            <div class="card-footer">
                <slot name="footer" />
            </div>
        </div>
    </a>
</template>

<script>

    import Icon from "@app/components/Common/Icon.vue";
    import Rating from "@app/components/Common/Rating.vue";

    export default {
        components: {
            Icon,
            Rating
        },
        props: {
            recipe: {
                type: Object,
                required: true
            },
            onClick: {
                type: Function,
                required: false,
                default: null
            },
            clickLink: {
                type: String,
                required: false,
                default: null
            }
        },
        computed: {
            clickHref() {
                if (this.onClick) {
                    return "#";
                }
                if (this.clickLink) {
                    return this.clickLink;
                }
                return this.getLinkForRecipe(this.recipe);
            }
        },
        methods: {
            click(event) {
                event.preventDefault();
                this.onClick(this.recipe);
            },
            getLinkForRecipe(recipe) {
                const res = this.$router.resolve({
                    name: "ViewRecipe",
                    params: {recipeId: recipe.recipeId}
                });
                return res.href;
            }
        }
    }
</script>

<style scoped>

.image {
    width: 100%;
    max-height: 350px;
    overflow: hidden;
}

.image img {
    right: 0;
    left: 0;
    top: 0;
    bottom: 0 ;
    margin: auto auto;
}

</style>


