<template>
    <div
        @click="select()"
    >
        <p class="subtitle is-flex">
            <span class="is-flex-grow-1">{{ list.name }}</span>
            <span>
                <Icon
                    icon="times"
                    :action="true"
                    @click.stop="deleteList()"
                />
            </span>
        </p>
        <ul>
            <li
                v-for="item in unfinishedItems.slice(0, unfinishedItemsToShow)"
                :key="item.id"
                :class="{ 'is-crossed-out': item.checked }"
            >
                {{ item.name }}
            </li>
            <li v-if="unfinishedItems.length > unfinishedItemsToShow">
                ...
            </li>
        </ul>
        <hr v-if="unfinishedItems.length > 0 && finishedItems.length > 0">
        <ul>
            <li
                v-for="item in finishedItems.slice(0, finishedItemsToShow)"
                :key="item.id"
                :class="{ 'is-crossed-out': item.checked }"
            >
                {{ item.name }}
            </li>
            <li v-if="finishedItems.length > finishedItemsToShow">
                ...
            </li>
        </ul>
    </div>
</template>

<script>
    import Icon from "@app/components/Common/Icon.vue";

    export default {
        components: {
            Icon
        },
        props: {
            list: {
                type: Object,
                required: true
            }
        },
        emits: [
            "list-selected",
            "list-deleted"
        ],
        computed: {
            unfinishedItems() {
                let unfinishedItems = this.list.items.filter(item => item.checked !== true);
                unfinishedItems.sort((l, r) => l.order > r.order);
                return unfinishedItems;
            },
            finishedItems() {
                let finishedItems = this.list.items.filter(item => item.checked === true);
                finishedItems.sort((l, r) => l.order > r.order);
                return finishedItems;
            },
            unfinishedItemsToShow() {
                return 6 + (Math.max(0, 3 - this.finishedItems.length));
            },
            finishedItemsToShow() {
                return 3 + (Math.max(0, 6 - this.unfinishedItems.length));
            }
        },
        methods: {
            select() {
                this.$emit("list-selected", this.list);
            },
            deleteList() {
                this.$emit("list-deleted", this.list);
            }
        }
    }
</script>

<style scoped>

</style>
