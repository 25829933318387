<template>
    <div class="control has-icons-left">
        <input
            v-model="searchTerms"
            class="input is-medium"
            type="text"
            placeholder="Add people"
        >
        <span class="icon is-small is-left">
            <Icon icon="user" />
        </span>
        <div
            class="dropdown"
            :class="{'is-active': hasSearchResults }"
        >
            <div class="dropdown-menu">
                <div class="dropdown-content">
                    <a
                        v-for="user in userResults"
                        :key="user.id"
                        href="#"
                        class="dropdown-item"
                        @click="selectUser(user)"
                    >
                        <p class="is-size-5">{{ user.displayName }}</p>
                        <p class="is-size-6">{{ user.username }}</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from "lodash/debounce";

    import Icon from "@app/components/Common/Icon.vue";

    import {UserService} from "@app/services/ApplicationProxy.js";
    import alertService from "@app/services/AlertService.js";

    const userService = new UserService();

    export default {
        components: {
            Icon
        },
        emits: ["userSelected"],
        data() {
            return {
                searchTerms: "",
                userResults: []
            }
        },
        computed: {
            hasSearchResults() {
                return this.userResults.length > 0;
            }
        },
        watch: {
            searchTerms() {
                this.debouncedSearchUser();
            }
        },
        created() {
            this.debouncedSearchUser = debounce(this.searchUsers, 500);
        },
        methods: {
            searchUsers() {
                if (this.searchTerms.length < 1) {
                    this.userResults = [];
                    return;
                }
                userService.search(this.searchTerms).then(
                    users => {
                        this.userResults = users;
                    },
                    () => {
                        alertService.showErrorAlert("Failed to search for users.");
                    }
                )
            },
            selectUser(user) {
                this.$emit("userSelected", user);
                this.searchTerms = "";
                this.userResults = [];
            }
        }
    }
</script>

<style scoped>
    .dropdown {
        width: 100%;
    }

    .dropdown-menu {
        width: 100%;
    }

</style>
