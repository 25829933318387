<template>
    <app-modal
        ref="modal"
        title="Update Ingredient"
    >
        <div
            v-if="showErrorSection"
            class="box"
        >
            <p class="title is-4">
                Did the ingredient parse correctly?
            </p>
            <div class="block">
                <p class="is-size-5 has-text-weight-bold">
                    Original Text
                </p>
                <p>
                    {{ ingredient.scrapedText }}
                </p>
            </div>
            <div class="block">
                <p class="is-size-5 has-text-weight-bold">
                    Parsed Text
                </p>
                <p>
                    {{ prettyIngredient }}
                </p>
            </div>
            <div class="field is-grouped is-grouped-right">
                <p class="control">
                    <button
                        class="button is-success"
                        @click="hideErrorSection()"
                    >
                        Yes
                    </button>
                </p>
                <p class="control">
                    <button
                        class="button is-danger"
                        @click="submitFeedback()"
                    >
                        No
                    </button>
                </p>
            </div>
        </div>

        <div
            v-if="showThankYou"
            class="message is-success"
        >
            <div class="message-header">
                Feedback Submitted
                <button
                    class="delete"
                    @click="hideThankYou()"
                />
            </div>
            <div class="message-body">
                Thank you for submitting your feedback. We'll review and improve parsing in future releases.
            </div>
        </div>

        <div
            v-if="ingredient"
            class="box"
        >
            <p class="title is-4">
                Update Ingredient
            </p>

            <div
                v-if="!showErrorSection"
                class="block"
            >
                <p class="is-size-5 has-text-weight-bold">
                    Original
                </p>
                <p>
                    {{ ingredient.scrapedText }}
                </p>
            </div>

            <div class="columns">
                <div class="column">
                    <div class="field">
                        <label class="label">Amount</label>
                        <div class="control has-icons-left">
                            <app-fraction-input v-model="amount" />
                            <span class="icon is-small is-left">
                                <app-icon icon="weight" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="field">
                        <label class="label">Unit</label>
                        <app-unit-picker v-model="unit" />
                    </div>
                </div>
            </div>
            <div class="field">
                <label class="label">Name</label>
                <div class="control has-icons-left">
                    <input
                        v-model="name"
                        class="input"
                        type="text"
                        placeholder="Eggs"
                    >
                    <span class="icon is-small is-left">
                        <app-icon icon="bacon" />
                    </span>
                </div>
            </div>
        </div>

        <template #footer-buttons>
            <button
                class="button is-link"
                type="button"
                @click="update()"
            >
                Update
            </button>
        </template>
    </app-modal>
</template>

<script>
    import Modal from "@app/components/Common/Modal.vue";
    import * as Utils from "@app/services/Util.js";
    import Icon from "@app/components/Common/Icon.vue";
    import UnitPicker from "@app/components/Common/UnitPicker.vue";
    import FractionInput from "@app/components/Common/FractionInput.vue";

    import {ParseRecipeService} from "@app/services/ApplicationProxy.js";
    import alertService from "@app/services/AlertService.js";

    const parseRecipeService = new ParseRecipeService();

    export default {
        components: {
            "app-modal": Modal,
            "app-icon": Icon,
            "app-unit-picker": UnitPicker,
            "app-fraction-input": FractionInput
        },
        data() {
            return {
                ingredient: null,
                feedbackSubmitted: false,
                showThankYou: false,
                amount: 1,
                unit: 0,
                name: "",
                recipeSourceUrl: null
            }
        },
        computed: {
            prettyIngredient() {
                if (this.ingredient) {
                    return Utils.prettyIngredient(this.ingredient);
                }
                return "";
            },
            showErrorSection() {
                return this.ingredient !== null &&
                    this.ingredient.scrapedText &&
                    !this.feedbackSubmitted &&
                    !this.ingredient.modified &&
                    !this.ingredient.feedbackSubmitted;
            }
        },
        methods: {
            clear() {
                this.ingredient = null;
                this.showThankYou = false;
                this.feedbackSubmitted = false;

                this.amount = 1;
                this.unit = 0;
                this.name = "";
                this.recipeSourceUrl = null;
            },
            open(param) {
                this.clear();

                this.ingredient = param.ingredient;
                this.recipeSourceUrl = param.recipeSourceUrl;

                this.amount = this.ingredient.amount;
                this.unit = this.ingredient.unit;
                this.name = this.ingredient.name;
                this.$refs.modal.open();
            },
            close() {
                this.$refs.modal.close();
            },
            hideErrorSection() {
                this.feedbackSubmitted = true;
                this.showThankYou = true;
            },
            hideThankYou() {
                this.showThankYou = false;
            },
            submitFeedback() {
                this.hideErrorSection();

                let feedback = {
                    rawIngredientText: this.ingredient.scrapedText,
                    parsedIngredientText: this.prettyIngredient,
                    recipeSourceUrl: this.recipeSourceUrl,
                    parsedIngredient: {
                        amount: this.ingredient.amount,
                        unit: this.ingredient.unit,
                        name: this.ingredient.name
                    }
                };
                parseRecipeService.submitFeedbackParsedIngredient(feedback).then(
                    () => {
                        this.ingredient.feedbackSubmitted = true;
                    },
                    () => {
                        alertService.showErrorAlert("Failed to submit feedback");
                    }
                );
            },
            update() {
                this.ingredient.name = this.name;
                this.ingredient.amount = this.amount;
                this.ingredient.unit = this.unit;
                this.ingredient.modified = true;

                this.close();
            }
        }
    }
</script>

