<template>
    <div id="app">
        <div v-if="authenticated">
            <nav
                class="navbar is-dark"
                role="navigation"
            >
                <div class="navbar-brand">
                    <router-link
                        :to="{ name: 'Home' }"
                        class="navbar-item"
                    >
                        <span class="icon is-large has-text-danger">
                            <Icon
                                icon="utensils"
                                class="fa-2x"
                            />
                        </span>
                    </router-link>
                </div>
                <div class="navbar-menu">
                    <div class="navbar-start">
                        <router-link
                            :to="{ name: 'Home' }"
                            class="navbar-item"
                        >
                            Recipes
                        </router-link>
                        <router-link
                            :to="{ name: 'Grocery' }"
                            class="navbar-item"
                        >
                            Grocery
                        </router-link>
                        <router-link
                            :to="{ name: 'Nutrients' }"
                            class="navbar-item"
                            v-if="user.tier === $constants.USER_TIER.SUPER"
                        >
                            Nutrients
                        </router-link>
                    </div>
                </div>
                <div class="navbar-menu">
                    <div class="navbar-end">
                        <div
                            v-if="user"
                            class="navbar-item has-dropdown is-hoverable"
                        >
                            <a class="navbar-link">
                                {{ user.name }}
                            </a>
                            <div class="navbar-dropdown">
                                <router-link
                                    :to="{name: 'Account'}"
                                    class="navbar-item"
                                >
                                    Account
                                </router-link>
                                <a class="navbar-item">
                                    Logout
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <router-view />
        </div>
        <div v-else>
            <Loader
                :size="20"
                :page="true"
            />
        </div>
    </div>
</template>

<script>
    import {computed} from "vue";

    import {useUserStore} from "@app/stores/UserStore.js";
    import Icon from "@app/components/Common/Icon.vue";
    import Loader from "@app/components/Common/Loader.vue";

    export default {
        components: {
            Icon,
            Loader
        },
        setup() {
            const userStore = useUserStore();
            const user = computed(() => userStore.currentUser);
            const authenticated = computed(() => userStore.authenticated);

            return {
                userStore,
                user,
                authenticated
            }
        },
        created() {
            this.userStore.fetchCurrentUser();
        }
    }
</script>
