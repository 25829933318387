<template>
    <app-modal
        ref="modal"
        title="Edit Steps"
        width="85%"
    >
        <p>
            Please enter the steps for the recipe separated by a blank line.
        </p>
        <div class="field pt-2">
            <div class="control">
                <textarea
                    v-model="steps"
                    class="textarea"
                    rows="20"
                />
            </div>
        </div>

        <template #footer-buttons>
            <button
                v-if="!processing"
                class="button is-link"
                type="button"
                @click="parse"
            >
                Done
            </button>
            <button
                v-if="processing"
                class="button is-link is-loading"
                type="button"
            >
                Parsing
            </button>
        </template>
    </app-modal>
</template>

<script>
    import {ParseRecipeService} from "@app/services/ApplicationProxy";
    import * as Utils from "@app/services/Util"

    import Modal from "@app/components/Common/Modal.vue";
    import alertService from "@app/services/AlertService.js";

    const parseRecipeService = new ParseRecipeService();

    export default {
        components: {
            "app-modal": Modal
        },
        props: {
            modelValue: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        emits: ["update:modelValue"],
        data() {
            return {
                steps: "",
                processing: false
            }
        },
        methods: {
            parse() {
                this.processing = true
                parseRecipeService.parseSteps(this.steps).then(
                    data => {
                        this.close();
                        this.$emit("update:modelValue", data.stepCategories);

                    },
                    () => {
                        alertService.showErrorAlert("Failed to parse steps.");
                        this.processing = false;
                    }
                );
            },
            convertStepsToText(stepCategories) {
                let items = []

                for (let i = 0; i < stepCategories.length; i++) {
                    let category = stepCategories[i];
                    if (!Utils.isStringNullOrBlank(category.name)) {
                        // let's push an empty element as well, to add another line of separation between categories / steps
                        if (items.length > 0) {
                            items.push("");
                        }
                        items.push(`${category.name}:`)
                    }
                    items = items.concat(category.steps.map(s => s.description));
                }

                return items.join("\n\n");
            },
            clear() {
                this.steps = "";
                this.processing = false;
            },
            open() {
                this.clear();
                if (this.modelValue) {
                    this.steps = this.convertStepsToText(this.modelValue);
                }
                this.$refs.modal.open();
            },
            close() {
                this.$refs.modal.close();
            }
        }
    }
</script>
