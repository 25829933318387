<template>
    <app-modal
        ref="modal"
        title="Add Tag to Recipe"
    >
        <div class="field">
            <label class="label">Name</label>
            <div class="control">
                <input
                    v-model="name"
                    class="input"
                    type="text"
                    placeholder="Tag Name"
                >
            </div>
        </div>

        <template #footer-buttons>
            <button
                class="button"
                type="button"
                @click="create"
            >
                Create
            </button>
        </template>
    </app-modal>
</template>

<script>
    import {RecipeService} from "@app/services/ApplicationProxy";

    import Modal from "@app/components/Common/Modal.vue"
    import alertService from "@app/services/AlertService.js";

    const recipeService = new RecipeService();

    export default {
        components: {
            "app-modal": Modal
        },
        props: {
            recipeId: {
                type: Number,
                required: true
            }
        },
        emits: ["new-tag"],
        data: function() {
            return {
                name: ""
            }
        },
        methods: {
            create() {
                recipeService.addTag(this.recipeId, {
                    name: this.name
                }).then(
                    data => {
                        this.close();
                        this.$emit("new-tag", data)
                    },
                    () => {
                        alertService.showErrorAlert("Failed to add tag to recipe.");
                    }
                )
            },
            open() {
                this.clear();
                this.$refs.modal.open();
            },
            close() {
                this.$refs.modal.close();
            },
            clear() {
                this.name = "";
            }
        }
    }
</script>
