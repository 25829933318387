import {createRouter, createWebHistory} from "vue-router";

import Home from "@app/views/Home.vue"
import Recipe from "@app/views/Recipe.vue";
import NewRecipe from "@app/views/NewRecipe.vue"
import Account from "@app/views/Account.vue"
import Grocery from "@app/views/Grocery.vue";
import Nutrients from "@app/views/Nutrients.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/recipe/create/:scrapedRecipeId?",
        name: "CreateRecipe",
        component: NewRecipe,
        props(route) {
            const props = {...route.params};
            props.scrapedRecipeId = parseInt(props.scrapedRecipeId, 10);
            return props;
        }
    },
    {
        path: "/recipe/:recipeId",
        name: "ViewRecipe",
        component: Recipe,
        props(route) {
            const props = {...route.params};
            props.recipeId = parseInt(props.recipeId, 10);
            return props;
        }
    },
    {
        path: "/recipe/:recipeId/edit",
        name: "EditRecipe",
        component: NewRecipe,
        props(route) {
            const props = {...route.params};
            props.recipeId = parseInt(props.recipeId, 10);
            return props;
        }
    },
    {
        path: "/grocery/",
        name: "Grocery",
        component: Grocery
    },
    {
        path: "/nutrients/",
        name: "Nutrients",
        component: Nutrients
    },
    {
        path: "/account",
        name: "Account",
        component: Account
    }
];


const router = createRouter({
    // eslint-disable-next-line no-process-env,no-undef
    history: createWebHistory("/"),
    routes
});

export default router
