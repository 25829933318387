<template>
    <app-modal
        ref="modal"
        title="Scrape a Recipe"
    >
        <p>
            Please enter the URL in which you wish to create the recipe from. Once we have processed the
            recipe you will be redirected to view the results and save the recipe.
        </p>

        <div class="field">
            <label class="label">Source URL</label>
            <div class="control">
                <input
                    v-model="sourceUrl"
                    class="input"
                    type="text"
                    placeholder="URL of recipe to scrape"
                    :disabled="waiting"
                >
            </div>
        </div>


        <template #footer-buttons>
            <button
                class="button"
                type="button"
                :class="{'is-loading is-danger': waiting}"
                @click="scrape"
            >
                Scrape
            </button>
        </template>
    </app-modal>
</template>

<script>
    import {ScrapedRecipeService} from "@app/services/ApplicationProxy"
    import {getErrorDescriptionForCode, DEFAULT_ERROR_MESSAGE} from "@app/services/ErrorStrings";

    import Modal from "@app/components/Common/Modal.vue"
    import alertService from "@app/services/AlertService.js";

    const scrapedRecipeService = new ScrapedRecipeService();

    export default {
        components: {
            "app-modal": Modal
        },
        emits: ["recipe-scraped"],
        data: function() {
            return {
                sourceUrl: "",
                scrapedRecipeId: -1,
                waiting: false
            }
        },
        methods: {
            scrape() {
                scrapedRecipeService.create({
                    sourceUrl: this.sourceUrl
                }).then(data => {
                    this.scrapedRecipeId = data.scrapedRecipeId;
                    this.waiting = true;
                    setTimeout(this.fetchScrapedRecipe, 500);
                })
            },
            fetchScrapedRecipe() {
                scrapedRecipeService.get(this.scrapedRecipeId).then(
                    data => {
                        if (data.status === 1) {
                            // Pending, re ping
                            setTimeout(this.fetchScrapedRecipe, 500);
                        }
                        else if (data.status === 2) {
                            this.waiting = false;
                            this.close();

                            this.$emit("recipe-scraped", data.scrapedRecipe);
                        }
                        else {
                            let errorMessage = getErrorDescriptionForCode(data.errorCode);
                            alertService.showErrorAlert(errorMessage, {duration: 6 * 1000});
                            this.waiting = false;
                            this.close();
                        }
                    },
                    () => {
                        alertService.showErrorAlert(DEFAULT_ERROR_MESSAGE);
                        this.waiting = false;
                    }
                );
            },
            open() {
                this.clear();
                this.$refs.modal.open();
            },
            close() {
                this.$refs.modal.close();
            },
            clear() {
                this.sourceUrl = "";
                this.waiting = false;
            }
        }

    }


</script>
