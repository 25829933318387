<template>
    <div style="min-width: 28em;">
        <table class="table is-bordered-outside is-fullwidth">
            <tbody>
                <tr>
                    <th />
                    <th />
                    <th class="has-text-right">
                        % Daily Value
                    </th>
                </tr>
                <tr>
                    <th>Calories</th>
                    <td>{{ food.calories }}</td>
                    <td />
                </tr>
                <tr>
                    <th>Total Fat</th>
                    <td>{{ round(food.totalFat, 1) }}g</td>
                    <td class="has-text-right">
                        {{ calculateDailyValue(NUTRIENT_TYPE.TOTAL_FAT, food.totalFat) }}%
                    </td>
                </tr>
                <tr>
                    <th>&emsp;Cholesterol</th>
                    <td>{{ round(food.cholesterol, 1) }}mg</td>
                    <td class="has-text-right">
                        {{ calculateDailyValue(NUTRIENT_TYPE.CHOLESTEROL, food.cholesterol) }}%
                    </td>
                </tr>
                <tr>
                    <th>&emsp;Sodium</th>
                    <td>{{ round(food.sodium, 1) }}mg</td>
                    <td class="has-text-right">
                        {{ calculateDailyValue(NUTRIENT_TYPE.SODIUM, food.sodium) }}%
                    </td>
                </tr>
                <tr>
                    <th>Total Carbohydrates</th>
                    <td>{{ round(food.totalCarbohydrates, 1) }}g</td>
                    <td class="has-text-right">
                        {{ calculateDailyValue(NUTRIENT_TYPE.TOTAL_CARBOHYDRATES, food.totalCarbohydrates) }}%
                    </td>
                </tr>
                <tr>
                    <th>Fiber</th>
                    <td>{{ round(food.fiber, 1) }}g</td>
                    <td class="has-text-right">
                        {{ calculateDailyValue(NUTRIENT_TYPE.FIBER, food.fiber) }}%
                    </td>
                </tr>
                <tr>
                    <th>Sugars</th>
                    <td>{{ round(food.sugars, 1) }}g</td>
                    <td />
                </tr>
                <tr>
                    <th>Protein</th>
                    <td>{{ round(food.protein, 1) }}g</td>
                    <td class="has-text-right">
                        {{ calculateDailyValue(NUTRIENT_TYPE.PROTEIN, food.protein) }}%
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
    import {NUTRIENT_TYPE, NUTRIENT_RECOMMENDED_DAILY_VALUE} from "@app/services/Constants.js";
    import {round} from "@app/services/Util.js";

    defineProps({
        "food": {
            type: Object,
            required: true
        }
    });

    function calculateDailyValue(nutrientType, amount) {
        if (nutrientType in NUTRIENT_RECOMMENDED_DAILY_VALUE) {
            return round(amount / NUTRIENT_RECOMMENDED_DAILY_VALUE[nutrientType] * 100, 0);
        }
        return null;
    }
</script>

<style scoped>
    .table.is-bordered-outside {
        border: 2px solid #b5bbbf;
    }
</style>

