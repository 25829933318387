<template>
    <div
        v-if="recipes.length > 0"
        class="columns is-multiline pt-3"
    >
        <div
            v-for="recipe in recipes"
            :key="recipe.recipeId"
            class="column is-one-quarter is-align-items-stretch"
        >
            <slot
                name="recipe-card"
                :recipe="recipe"
            >
                <app-recipe-card :recipe="recipe" />
            </slot>
        </div>
    </div>
    <div
        v-else
        class="has-text-centered pt-3"
    >
        <slot name="no-recipes-exist" />
    </div>
</template>

<script>
    import RecipeCard from "@app/components/Recipe/RecipeCard.vue";

    export default {
        components: {
            "app-recipe-card": RecipeCard
        },
        props: {
            recipes: {
                type: Array,
                required: true
            }
        }
    }
</script>
