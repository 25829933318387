<template>
    <div>
        <section>
            <div class="container">
                <div class="title">
                    <span>Notes</span>
                </div>

                <article
                    class="message is-dark"
                >
                    <div class="message-header">
                        Add Note
                    </div>
                    <div class="message-body">
                        <textarea
                            v-model="newNote"
                            class="textarea has-fixed-size"
                        />
                        <div class="field is-grouped is-grouped-right pt-3">
                            <p class="control">
                                <button
                                    class="button"
                                    @click="clearNote"
                                >
                                    Clear
                                </button>
                            </p>
                            <p class="control">
                                <button
                                    class="button is-link"
                                    type="submit"
                                    @click="createNote"
                                >
                                    Add
                                </button>
                            </p>
                        </div>
                    </div>
                </article>

                <div
                    v-for="note in notes"
                    :key="note.noteId"
                    class="card m-3"
                >
                    <div class="card-content">
                        <p class="is-size-5">
                            {{ note.content }}
                        </p>
                        <p> {{ note.modifiedDateDisplay }} </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {DateTime} from "luxon";

    import {RecipeNoteService} from "@app/services/ApplicationProxy";
    import alertService from "@app/services/AlertService.js";


    const recipeNoteService = new RecipeNoteService();

    export default {
        props: {
            recipeId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                notes: [],
                newNote: ""
            }
        },
        created() {
            this.fetchNotes();
        },
        methods: {
            fetchNotes() {
                recipeNoteService.get(this.recipeId).then(
                    data => {
                        this.update(data);
                    },
                    () => {
                        alertService.showErrorAlert("Failed to fetch notes for recipe.");
                    }
                )
            },
            update(notes) {
                this.notes = notes.map(n => this.transformNote(n));
            },
            transformNote(note) {
                note.modifiedDateDisplay = DateTime.fromMillis(note.modifiedDate).toFormat("ff");

                return note;
            },
            deleteNote(note) {
                recipeNoteService.delete(this.recipeId, note.noteId).then(
                    () => {
                        let ind = this.notes.indexOf(note);
                        this.notes.splice(ind, 1);
                    },
                    () => {
                        alertService.showErrorAlert("Failed to delete note.");
                    }
                )
            },
            clearNote() {
                this.newNote = "";
            },
            createNote() {
                recipeNoteService.create(this.recipeId, {
                    content: this.newNote
                }).then(
                    data => {
                        this.notes.unshift(this.transformNote(data));
                        this.clearNote();
                    },
                    () => {
                        alertService.showErrorAlert("Failed to create note.");
                    }
                )
            }

        }
    }
</script>
