<template>
    <div class="control has-icons-left">
        <div class="select">
            <select v-model="value">
                <option
                    v-for="permission in $constants.SELECTABLE_RECIPE_PERMISSION_LEVELS"
                    :key="permission"
                    :value="permission"
                >
                    {{ $friendlyConstants.RECIPE_PERMISSION_LEVEL[permission] }}
                </option>
            </select>
        </div>
        <span class="icon is-small is-left">
            <Icon icon="key" />
        </span>
    </div>
</template>

<script>
    import Icon from "@app/components/Common/Icon.vue";

    export default {
        components: {
            Icon
        },
        props: {
            modelValue: {
                type: Number,
                required: false,
                default: () => 0
            }
        },
        emits: ["update:modelValue"],
        data() {
            return {

            };
        },
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                }
            }
        }
    }
</script>

<style scoped>

</style>
