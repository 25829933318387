
const DEFAULT_ERROR_MESSAGE = "An unknown problem arose.";

const ERROR_CODES = {
    "UNKNOWN": DEFAULT_ERROR_MESSAGE,
    "SCRAPE_UNKNOWN": "An unknown problem arose while trying to scrape the recipe.",
    "SCRAPE_UNSUPPORTED": "Importing recipes from the given website is currently unsupported. You can still enter the recipe information manually."
}

function getErrorDescriptionForCode(code) {

    if (ERROR_CODES[code]) {
        return ERROR_CODES[code];
    }

    return DEFAULT_ERROR_MESSAGE;
}

export {
    ERROR_CODES,
    DEFAULT_ERROR_MESSAGE,
    getErrorDescriptionForCode
}
