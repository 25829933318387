<template>
    <div>
        <h5 class="subtitle is-5">
            People with access
        </h5>
        <RecipePermissionArticle
            v-for="permission in permissions"
            :key="permission.grantedUser.id + '__' + permission.permission"
            :permission="permission"
            :editable="true"
            @update:permission="updatePermission"
            @delete:permission="deletePermission"
        />

        <div
            v-if="globalPermissions.length > 0"
            class="pt-5"
        >
            <h5 class="subtitle is-5">
                People with global access
            </h5>
            <span>
                A recipe owner can assign access to a user to all of their recipes in their account settings.
            </span>
            <RecipePermissionArticle
                v-for="permission in globalPermissions"
                :key="permission.grantedUser.id + '__' + permission.permission"
                :permission="permission"
                :editable="false"
            />
        </div>
    </div>
</template>

<script>

    import {RecipePermissionService} from "@app/services/ApplicationProxy.js";
    import alertService from "@app/services/AlertService.js";
    
    import RecipePermissionArticle from "@app/components/Recipe/Permissions/RecipePermissionArticle.vue";

    const recipePermissionService = new RecipePermissionService();

    export default {
        components: {
            RecipePermissionArticle
        },
        props: {
            recipeId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                permissions: [],
                globalPermissions: []
            }
        },
        created() {
            this.fetchPermissions();
        },
        methods: {
            fetchPermissions() {
                recipePermissionService.get(this.recipeId).then(
                    permissions => {
                        this.permissions = permissions.filter(p => p.recipeId != null);
                        this.globalPermissions = permissions.filter(p => p.recipeId == null);
                    },
                    () => {
                        alertService.showErrorAlert("Failed to fetch recipe permissions.");
                    }
                )
            },
            updatePermission({permission, permissionLevel}) {
                const oldPermission = permission.permission;
                permission.permission = permissionLevel;

                recipePermissionService.grant(permission.grantedUser.id, permissionLevel, this.recipeId).then(
                    () => {},
                    () => {
                        // reset the permission on failure
                        permission.permission = oldPermission;
                        alertService.showErrorAlert("Failed to update recipe permissions for " + permission.grantedUser.displayName);
                    }
                );
            },
            deletePermission(permission) {
                recipePermissionService.revoke(permission.grantedUser.id, this.recipeId).then(
                    () => {
                        this.$utils.Arrays.remove(this.permissions, permission);
                    },
                    () => {
                        alertService.showErrorAlert("Failed to remove recipe permissions for " + permission.grantedUser.displayName);
                    }
                );
            },
            clear() {
                this.permissions = [];
                this.globalPermissions = [];
            }
        }
    }
</script>

<style scoped>

</style>
