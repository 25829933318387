import {createApp} from "vue"
import {createPinia} from "pinia";

import App from "@app/App.vue"
import router from "@app/router"

// Import bulma
import "bulma/css/bulma.min.css"

// Import bulma theme
import "bulmaswatch/lux/bulmaswatch.min.css"

// Import custom styles
import "@app/css/site.css"

// Import starability
import "starability/starability-minified/starability-basic.min.css"

// Import utils
import * as Utils from "@app/services/Util.js";
import * as Constants from "@app/services/Constants.js";
import * as FriendlyConstants from "@app/services/FriendlyConstants.js";
import {clickOutside} from "@app/services/CustomDirectives.js";

const app = createApp(App);

app.use(router);
app.use(createPinia());

app.config.globalProperties.$utils = Utils;
app.config.globalProperties.$constants = Constants;
app.config.globalProperties.$friendlyConstants = FriendlyConstants;

app.directive("click-outside", clickOutside);

app.mount("#app");

