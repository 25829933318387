<template>
    <article class="media">
        <div class="media-content">
            <div class="is-flex is-flex-direction-row">
                <p class="image is-64x64 pr-3 is-flex is-align-items-center">
                    <img
                        src="https://bulma.io/images/placeholders/128x128.png"
                        alt=""
                    >
                </p>
                <div class="is-flex-grow-1">
                    <p>{{ permission.grantedUser.displayName }}</p>
                    <p>{{ permission.grantedUser.username }}</p>
                </div>
                <div class="is-flex is-align-items-center">
                    <div v-if="editable && permission.permission < $constants.RECIPE_PERMISSION_LEVEL.OWNER">
                        <RecipePermissionPicker
                            :model-value="permission.permission"
                            @update:model-value="(permissionLevel) => $emit('update:permission', {permission, permissionLevel})"
                        />
                    </div>
                    <span v-else>{{ $friendlyConstants.RECIPE_PERMISSION_LEVEL[permission.permission] }}</span>
                </div>
                <div
                    v-if="editable && permission.permission < $constants.RECIPE_PERMISSION_LEVEL.OWNER"
                    class="is-flex is-align-items-center"
                >
                    <span class="icon is-size-4 is-medium">
                        <Icon
                            icon="times"
                            :action="true"
                            @click="$emit('delete:permission', permission)"
                        />
                    </span>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
    import Icon from "@app/components/Common/Icon.vue";
    import RecipePermissionPicker from "@app/components/Common/RecipePermissionPicker.vue";

    export default {
        components: {
            Icon,
            RecipePermissionPicker
        },
        props: {
            permission: {
                type: Object,
                required: true
            },
            editable: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        emits: [
            "delete:permission",
            "update:permission"
        ]
    }
</script>

<style scoped>

</style>
