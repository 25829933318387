<template>
    <div
        class="modal"
        :class="{'is-active': show }"
    >
        <div class="modal-background" />
        <div class="modal-content box has-text-centered">
            <span class="is-size-5">
                {{ message }}
            </span>
            <progress
                class="progress is-large is-info"
                max="100"
            />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            message: {
                type: String,
                required: false,
                default: ""
            }
        },
        data() {
            return {
                show: false
            }
        },
        methods: {
            open() {
                this.show = true;
            },
            close() {
                this.show = false;
            }

        }
    }
</script>