// eslint-disable-next-line max-classes-per-file
import Proxy from "@app/services/APIService.js";

import PopularTag from "@app/models/PopularTag";
import User from "@app/models/User";

import * as Utils from "@app/services/Util.js";

const defaultBaseUrl = "/api/";

class RecipeService {

    constructor(baseUrl = defaultBaseUrl) {
        this.baseUrl = baseUrl;
        this.proxy = new Proxy(baseUrl);
    }

    get(id) {
        return this.proxy.get(`recipe/${id}`);
    }

    getAll(searchTerms, tags, sortBy, sortAscending, ownershipFilter, tagFilter) {
        let params = "";
        // If tags were passed, create the tag parameters
        if (tags && tags.length > 0) {
            params = "?tag=" + tags.join("&tag=");
        }
        if (!Utils.isStringNullOrBlank(searchTerms)) {
            let startChar = Utils.isStringNullOrBlank(params) ? "?" : "&";
            params += `${startChar}searchTerms=${searchTerms}`
        }

        if (!Utils.isStringNullOrBlank(sortBy)) {
            let startChar = Utils.isStringNullOrBlank(params) ? "?" : "&";
            params += `${startChar}sortBy=${sortBy}`
        }

        let startChar = Utils.isStringNullOrBlank(params) ? "?" : "&";
        params += `${startChar}ascending=${sortAscending === true ? "true" : "false"}`

        if (ownershipFilter != null) {
            params += `&ownershipFilter=${ownershipFilter}`;
        }

        if (tagFilter != null) {
            params += `&tagFilter=${tagFilter}`;
        }

        return this.proxy.get(`recipe/${params}`);
    }

    create(recipe) {
        return this.proxy.post("v3/recipe/", recipe);
    }

    update(id, recipe) {
        return this.proxy.put(`v3/recipe/${id}`, recipe);
    }

    delete(id) {
        return this.proxy.delete(`recipe/${id}`);
    }

    addTag(id, tag) {
        return this.proxy.post(`recipe/${id}/tag`, tag);
    }

    removeTag(id, tagKey) {
        return this.proxy.delete(`recipe/${id}/tag/${tagKey}`)
    }

    updateFeatureImageUrl(id, imageUrl) {
        return this.proxy.postRawString(`recipe/${id}/feature-image/`, imageUrl);
    }

    uploadFeatureImage(id, content, contentType) {
        let formData = new FormData();
        formData.append("file", new Blob([content], {
            type: contentType
        }));

        return this.proxy.request({
            url: `recipe/${id}/feature-image/upload/`,
            method: "POST",
            headers: {
                "Content-Type": "ultipart/form-data"
            },
            data: formData
        });
    }

    removeFeatureImage(id) {
        return this.proxy.delete(`recipe/${id}/feature-image/`);
    }

    addRelatedRecipe(id, relatedRecipeId) {
        return this.proxy.post(`recipe/${id}/related/${relatedRecipeId}`);
    }

    removeRelatedRecipe(id, relatedRecipeId) {
        return this.proxy.delete(`recipe/${id}/related/${relatedRecipeId}`);
    }

    rate(id, rating) {
        return this.proxy.post(`recipe/${id}/rate`, {
            rating: rating
        });
    }

    view(id) {
        return this.proxy.post(`recipe/${id}/view`);
    }

    getRecentlyViewed(limit) {
        let url = "recipe/recently_viewed";
        if (limit) {
            url += `?limit=${limit}`;
        }
        return this.proxy.get(url);
    }

    getExportUrl(recipeId, format) {
        return `${this.baseUrl}/recipe/${recipeId}/export?format=${format}`
    }
}

class RecipePermissionService {

    constructor(baseUrl = defaultBaseUrl) {
        this.proxy = new Proxy(baseUrl);
    }

    get(recipeId) {
        return this.proxy.get(`recipe/${recipeId}/permission`)
    }

    getGlobal() {
        return this.proxy.get("recipe/permission");
    }

    grant(userId, permission, recipeId = null) {
        const req = {
            permission: permission
        };
        let url = "recipe";
        if (recipeId != null) {
            url += `/${recipeId}`;
        }
        return this.proxy.post(`${url}/permission/${userId}`, req);
    }

    revoke(userId, recipeId = null) {
        let url = "recipe";
        if (recipeId != null) {
            url += `/${recipeId}`;
        }
        return this.proxy.delete(`${url}/permission/${userId}`);
    }

}

class ScrapedRecipeService {

    constructor(baseUrl = defaultBaseUrl) {
        this.proxy = new Proxy(baseUrl);
    }

    get(id) {
        return this.proxy.get(`recipe/scrape/${id}`);
    }

    create(scrapedRecipe) {
        return this.proxy.post("recipe/scrape/", scrapedRecipe);
    }
}

class ParseRecipeService {

    constructor(baseUrl = defaultBaseUrl) {
        this.proxy = new Proxy(baseUrl);
    }

    parseIngredients(ingredients) {
        return this.proxy.postRawString("v2/recipe/parse/ingredients", ingredients)
    }

    parseSteps(steps) {
        return this.proxy.postRawString("v2/recipe/parse/steps", steps)
    }

    submitFeedbackParsedIngredient(feedback) {
        return this.proxy.post("v2/recipe/parse/ingredients/feedback", feedback);
    }
}

class RecipeSnapshotService {

    constructor(baseUrl = defaultBaseUrl) {
        this.baseUrl = baseUrl;
        this.proxy = new Proxy(baseUrl);
    }

    get(recipeId, id) {
        return this.proxy.get(`recipe/${recipeId}/snapshot/${id}`);
    }

    getLatest(recipeId) {
        return this.proxy.get(`recipe/${recipeId}/snapshot/latest`);
    }

    create(recipeId, type) {
        return this.proxy.post(`recipe/${recipeId}/snapshot?type=${type}`);
    }

    getDownloadUrl(recipeId, id) {
        return `${this.baseUrl}/recipe/${recipeId}/snapshot/${id}/download`
    }
}

class RecipeNoteService {

    constructor(baseUrl = defaultBaseUrl) {
        this.proxy = new Proxy(baseUrl);
    }

    get(recipeId) {
        return this.proxy.get(`recipe/${recipeId}/note`);
    }

    create(recipeId, note) {
        return this.proxy.post(`recipe/${recipeId}/note`, note);
    }

    update(recipeId, id, note) {
        return this.proxy.put(`recipe/${recipeId}/note/${id}`, note)
    }

    delete(recipeId, id) {
        return this.proxy.delete(`recipe/${recipeId}/note/${id}`)
    }
}

class TagService {

    constructor(baseUrl = defaultBaseUrl) {
        this.proxy = new Proxy(baseUrl);
    }

    getPopular() {
        return this.proxy.get("tag/popular").then(res => res.map(t => Object.assign(new PopularTag(), t)))
    }

    search(searchTerms) {
        return this.proxy.get(`tag/?searchTerms=${searchTerms}`);
    }

}

class UserService {

    constructor(baseUrl = defaultBaseUrl) {
        this.proxy = new Proxy(baseUrl);
        this.baseUrl = baseUrl;
    }

    me() {
        return this.proxy.get("user/me").then(res => new User(res));
    }

    stats() {
        return this.proxy.get("user/me/stats");
    }

    search(searchTerms) {
        return this.proxy.get(`user/search?searchTerms=${searchTerms}`);
    }

    getAccountManagementUrl(returnToUrl) {
        return encodeURI(this.baseUrl + "user/account-management?returnToUrl=" + returnToUrl);
    }
}

class GroceryListService {

    constructor(baseUrl = defaultBaseUrl) {
        this.proxy = new Proxy(baseUrl);
    }

    get(id) {
        return this.proxy.get(`grocery-list/${id}`)
    }

    getAll() {
        return this.proxy.get("grocery-list");
    }

    create(groceryList) {
        return this.proxy.post("grocery-list", groceryList);
    }

    update(id, groceryList) {
        return this.proxy.put(`grocery-list/${id}`, groceryList);
    }

    delete(id) {
        return this.proxy.delete(`grocery-list/${id}`);
    }
}

class NutrientService {

    constructor(baseUrl = defaultBaseUrl) {
        this.proxy = new Proxy(baseUrl);
    }

    search(foodItems) {
        return this.proxy.post("nutrients/search", {
            foodItems: foodItems
        });
    }

}


export {
    RecipeService,
    RecipePermissionService,
    ScrapedRecipeService,
    ParseRecipeService,
    RecipeSnapshotService,
    RecipeNoteService,
    TagService,
    UserService,
    GroceryListService,
    NutrientService
};
