<template>
    <div>
        <div class="field has-addons no-margin-bottom ">
            <slot name="controls-left" />
            <div class="control">
                <a
                    class="button"
                    @click.prevent="toggleShowPopularTags"
                >
                    <app-icon
                        :icon="showPopularTags ? 'chevron-up' : 'chevron-down'"
                        class="fa-lg"
                    />
                </a>
            </div>
            <div class="control is-expanded">
                <div
                    v-click-outside="clearSearchedTags"
                    class="dropdown full-width"
                    :class="{ 'is-active': showTagSearchDropdown }"
                >
                    <div
                        class="dropdown-trigger full-width"
                    >
                        <div class="tagsfield input">
                            <div
                                v-for="tag in selectedTags"
                                :key="tag.tagId"
                                class="control"
                            >
                                <div class="tags has-addons">
                                    <span class="tag is-link">
                                        {{ tag.name }}
                                    </span>
                                    <a
                                        class="tag is-delete"
                                        @click.prevent="unselectTag(tag)"
                                    />
                                </div>
                            </div>
                            <div>
                                <input type="hidden">
                                <contenteditable
                                    v-model="tagSearchTerms"
                                    tag="span"
                                    :contenteditable="true"
                                    :no-html="true"
                                    :no-nl="true"
                                    @returned="tagSearchEnterPressed"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-menu">
                        <div class="dropdown-content">
                            <div class="dropdown-item">
                                <div
                                    v-if="searchedTags.length > 0"
                                    class="field is-grouped is-grouped-multiline"
                                >
                                    <div
                                        v-for="tag in searchedTags"
                                        :key="tag.tagId"
                                        class="control"
                                    >
                                        <div
                                            class="tags has-addons are-medium action"
                                            @click="selectSearchedTag(tag)"
                                        >
                                            <span class="tag is-link">
                                                {{ tag.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <span v-else>
                                    No tags matched your search.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="control">
                        <a
                            class="button"
                            @click.prevent="clear"
                        >
                            <app-icon
                                icon="times"
                                class="fa-lg"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <slot name="controls-right" />
        </div>
        <div
            v-if="showPopularTags"
            class="popular-tag-container"
        >
            <div class="field is-grouped is-grouped-multiline">
                <span class="has-text-weight-bold pr-3">Popular Tags</span>
                <div
                    v-for="tag in notSelectedPopularTags"
                    :key="tag.tagId"
                    class="control"
                >
                    <div
                        class="tags has-addons are-medium action"
                        @click="selectTag(tag)"
                    >
                        <span class="tag is-link">
                            {{ tag.name }}
                        </span>
                        <span class="tag is-light has-text-weight-bold">
                            {{ tag.count }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import "bulma-tagsfield/dist/tagsfield.css";
    import contenteditable from "vue-contenteditable";
    import debounce from "lodash/debounce";

    import {TagService} from "@app/services/ApplicationProxy";
    import {Arrays, isStringNullOrBlank} from "@app/services/Util.js";

    import Icon from "@app/components/Common/Icon.vue";
    import alertService from "@app/services/AlertService.js";

    const tagService = new TagService();

    export default {
        components: {
            "app-icon": Icon,
            contenteditable
        },
        emits: ["selected-tags-updated"],
        data() {
            return {
                showPopularTags: false,
                popularTags: [],
                selectedTags: [],
                tagSearchTerms: "",
                searchedTags: []
            }
        },
        computed: {
            notSelectedPopularTags() {
                // remove all the tags that are selected
                return this.popularTags.filter(tag => this.selectedTags.indexOf(tag) === -1);
            },
            showTagSearchDropdown() {
                return !isStringNullOrBlank(this.tagSearchTerms);
            }
        },
        watch: {
            selectedTags: {
                handler(newTags) {
                    this.$emit("selected-tags-updated", newTags);
                },
                deep: true
            },
            tagSearchTerms(newSearchTerms) {
                if (isStringNullOrBlank(newSearchTerms)) {
                    this.searchedTags = [];
                }
                else {
                    this.debouncedSearchTags();
                }
            }
        },
        created() {
            this.fetchTags();

            this.debouncedSearchTags = debounce(this.searchTags, 500);
        },
        methods: {
            fetchTags() {
                tagService.getPopular().then(
                    data => {
                        this.popularTags = data
                    },
                    () => {
                        alertService.showErrorAlert("Failed to fetch popular tags");
                    }
                )
            },
            searchTags() {
                tagService.search(this.tagSearchTerms).then(
                    data => {
                        this.searchedTags = data;
                    },
                    () => {
                        alertService.showErrorAlert("Failed to search for tags");
                    }
                )
            },
            selectTag(tag) {
                this.selectedTags.push(tag);
            },
            unselectTag(tag) {
                Arrays.remove(this.selectedTags, tag);
            },
            clear() {
                this.selectedTags = [];
                this.clearSearchedTags();
            },
            clearSearchedTags() {
                this.tagSearchTerms = "";
                this.searchedTags = [];
            },
            tagSearchEnterPressed() {
                if (this.searchedTags.length === 1) {
                    this.selectSearchedTag(this.searchedTags[0]);
                }
            },
            selectSearchedTag(tag) {
                this.selectedTags.push(tag);
                this.searchedTags = [];
                this.tagSearchTerms = "";
            },
            toggleShowPopularTags() {
                this.showPopularTags = !this.showPopularTags;
            }
        }
    }
</script>

<style scoped>

    .no-margin-bottom {
        margin-bottom: 0 !important;
    }
    .popular-tag-container {
        background-color: #fff;
        /*border-radius: 6px;*/
        box-shadow: 0 0 0 1px #b5bbbf;
        color: #4a4a4a;
        display: block;
        padding: 1.25rem;
        margin-left: 1px;
        margin-right: 1px;
    }
    .full-width {
        width: 100%;
    }
</style>
