<template>
    <div>
        <section class="section">
            <div v-if="selectedList !== null">
                <div class="columns">
                    <div class="column is-one-quarter">
                        <ul>
                            <li
                                v-for="list in groceryLists"
                                :key="list.id"
                                class="box action"
                            >
                                <GroceryListBox
                                    :list="list"
                                    @list-selected="selectList"
                                    @list-deleted="deleteList"
                                />
                            </li>
                        </ul>
                    </div>
                    <div class="column">
                        <div class="box">
                            <div class="is-pulled-right">
                                <Icon
                                    icon="compress-arrows-alt"
                                    :action="true"
                                    @click="clearSelectedList()"
                                />
                            </div>
                            <EditGroceryList
                                v-model="selectedList"
                                :close="clearSelectedList"
                                :delete-list="deleteList"
                                :save-list="saveList"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="container"
            >
                <div class="columns is-multiline">
                    <div class="column is-one-quarter">
                        <div
                            class="box action"
                            @click="newList()"
                        >
                            <p>
                                <span>
                                    <Icon
                                        icon="plus"
                                        :action="true"
                                    />
                                </span>
                                &nbsp;
                                <span>New List</span>
                            </p>
                        </div>
                    </div>
                    <div
                        v-for="list in groceryLists"
                        :key="list.id"
                        class="column is-one-quarter"
                    >
                        <GroceryListBox
                            class="action box"
                            :list="list"
                            @list-selected="selectList"
                            @list-deleted="deleteList"
                        />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {GroceryListService} from "@app/services/ApplicationProxy.js";
    import {Arrays, generateTemporaryId, isStringNullOrBlank, isTemporaryIdOrNull} from "@app/services/Util.js";

    import EditGroceryList from "@app/components/Grocery/EditGroceryList.vue";
    import Icon from "@app/components/Common/Icon.vue";
    import GroceryListBox from "@app/components/Grocery/GroceryListBox.vue";
    import alertService from "@app/services/AlertService.js";
    import system from "@app/services/System.js";

    const groceryListService = new GroceryListService();

    export default {
        components: {
            Icon,
            EditGroceryList,
            GroceryListBox
        },
        data: function() {
            return {
                groceryLists: [],
                selectedList: null
            }
        },
        created() {
            this.fetchGroceryLists();

            system.setTitle("Grocery | DasCookbook");
        },
        methods: {
            fetchGroceryLists() {
                groceryListService.getAll().then(
                    groceryLists => {
                        this.groceryLists = groceryLists
                    },
                    () => {
                        alertService.showErrorAlert("Failed to fetch grocery lists");
                    }
                )
            },
            selectList(list) {
                const oldList = this.selectedList;
                this.selectedList = list;

                // if the previously selected list doesn't have a saved id, title, or any items, then remove it
                if (oldList !== null && isStringNullOrBlank(oldList.name) && oldList.items.length === 0) {
                    this.deleteList(oldList);
                }
            },
            clearSelectedList() {
                this.selectList(null);
            },
            newList() {
                const newList = {
                    // generate an id so we can keep track of it
                    id: generateTemporaryId(),
                    name: "",
                    items: []
                };
                Arrays.add(this.groceryLists, newList, 0);
                this.selectList(newList);
            },
            deleteList(list) {
                if (isTemporaryIdOrNull(list.id)) {
                    Arrays.remove(this.groceryLists, list);
                }
                else {
                    groceryListService.delete(list.id).then(
                        () => {
                            Arrays.remove(this.groceryLists, list);
                        },
                        () => {
                            alertService.showErrorAlert("Unexpected error occurred, please try again.");
                        }
                    )
                }
            },
            saveList(list) {
                if (list === null) {
                    const promise = new Promise();
                    promise.resolve();
                    return promise;
                }
                const vm = this.listToViewModel(list);

                let promise = null;
                if (isTemporaryIdOrNull(list.id)) {
                    promise = groceryListService.create(vm);
                }
                else {
                    promise = groceryListService.update(list.id, vm);
                }
                return promise.then(
                    groceryList => {
                        const newList = this.listFromViewModel(groceryList);
                        const listIndex = this.groceryLists.indexOf(list);
                        if (listIndex !== -1) {
                            Arrays.replace(this.groceryLists, list, newList);
                            if (this.selectedList === list) {
                                this.selectList(newList);
                            }
                        }

                        return newList;
                    },
                    () => {
                        alertService.showErrorAlert("Failed to save grocery list.");
                        return false;
                    }
                );
            },
            listToViewModel(list) {
                return {
                    name: list.name,
                    items: list.items.map(item => ({
                        // don't send an ID if we are just using it locally
                        id: isTemporaryIdOrNull(item.id) ? null : item.id,
                        name: item.name,
                        checked: item.checked === true
                    }))
                }
            },
            listFromViewModel(groceryList) {
                groceryList.items.sort((l, r) => l.order > r.order);
                return groceryList;
            }
        }
    }
</script>
<style scoped>

    .action:hover {
        transform: scale(1.075);
        cursor: pointer;
    }

</style>
