<template>
    <div>
        <div>
            <input
                v-model="groceryList.name"
                class="input is-large has-text-centered title grocery-list-name"
                placeholder="Title"
            >
        </div>
        <DraggableComponent
            v-model="people"
            group="peeps"
        >
            <template #item="{element}">
                <span>{{ element }}</span>
            </template>
        </DraggableComponent>
        <ul class="list">
            <DraggableComponent
                v-model="groceryList.items"
                handle=".item-handle"
                group="grocery-list-items"
                item-key="id"
            >
                <template #item="{element, index}">
                    <li
                        class="list-item"
                    >
                        <p class="is-flex is-flex-direction-row is-align-content-center">
                            <span class="pr-3 is-flex is-align-items-center is-justify-content-center is-size-4">
                                <Icon
                                    icon="grip-vertical"
                                    :action="false"
                                    class="item-handle"
                                />
                            </span>
                            <span class="checkbox">
                                <input
                                    v-model="element.checked"
                                    type="checkbox"
                                >
                            </span>
                            <span
                                class="is-flex-grow-5"
                                @click="setFocus(index)"
                            >
                                <span>
                                    <input
                                        :ref="'item-input-' + index"
                                        v-model="element.name"
                                        class="input"
                                        :class="{'is-crossed-out': element.checked}"
                                        type="text"
                                        :readonly="focusIndex !== index"
                                        @keydown.enter="addItem(index + 1, true)"
                                        @keydown.down.prevent="incrementFocus()"
                                        @keydown.up.prevent="decrementFocus()"
                                    >
                                </span>
                            </span>
                            <span class="pl-3 is-flex is-align-items-center is-justify-content-center is-size-4">
                                <Icon
                                    icon="times"
                                    :action="true"
                                    @click="deleteItem(element)"
                                />
                            </span>
                        </p>
                    </li>
                </template>
                <template #footer>
                    <li class="list-item">
                        <p class="is-flex is-flex-direction-row">
                            <span class="pr-3 is-flex is-align-items-center is-justify-content-center is-size-4">
                                <Icon
                                    icon="plus"
                                    :action="false"
                                />
                            </span>
                            <span
                                class="pr-5 is-flex-grow-5"
                                @click="setFocus(newItemIndex)"
                            >
                                <span>
                                    <input
                                        ref="new-item-input"
                                        v-model="newItemText"
                                        placeholder="New Item"
                                        class="input"
                                        type="text"
                                        :readonly="focusIndex !== newItemIndex"
                                        @keydown.up.prevent="decrementFocus()"
                                    >
                                </span>
                            </span>
                        </p>
                    </li>
                </template>
            </DraggableComponent>
        </ul>
        <div class="is-flex">
            <div class="field is-grouped is-flex-grow-1">
                <p class="control">
                    <button
                        class="button is-danger"
                        type="button"
                        @click="deleteListClick"
                    >
                        Delete
                    </button>
                </p>
            </div>
            <div class="field is-grouped">
                <p class="control">
                    <button
                        class="button is-link"
                        type="button"
                        @click="close"
                    >
                        Close
                    </button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from "lodash/debounce";
    import DraggableComponent from "vuedraggable";

    import {Arrays, isStringNullOrBlank, generateTemporaryId} from "@app/services/Util.js";
    import Icon from "@app/components/Common/Icon.vue";

    export default {
        components: {
            Icon,
            DraggableComponent
        },
        props: {
            modelValue: {
                type: Object,
                required: true
            },
            close: {
                type: Function,
                required: true
            },
            deleteList: {
                type: Function,
                required: true
            },
            saveList: {
                type: Function,
                required: true
            }
        },
        emits: ["update:modelValue"],
        data: function() {
            return {
                people: [
                    "Mitchell",
                    "Bob",
                    "Alex"
                ],
                focusIndex: 1,
                newItemText: ""
            }
        },
        computed: {
            newItemIndex() {
                return this.groceryList.items.length;
            },
            groceryList: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                }
            }
        },
        watch: {
            newItemText(newText) {
                // if new item element has text in it, then we want to convert it to an actual item
                // and transfer focus to it.
                if (!isStringNullOrBlank(newText)) {
                    this.newItemText = "";
                    this.groceryList.items.push({
                        id: generateTemporaryId(),
                        name: newText,
                        checked: false
                    });
                    this.setFocus(this.groceryList.items.length - 1, true, newText.length + 1);
                }
            },
            groceryList: {
                handler(newValue, oldValue) {
                    // we don't want to save unless the instances are the same, to prevent triggering a save when
                    //  we re-populate the array with the updates from the server (which will make this infinite)
                    if (newValue === oldValue) {
                        this.debouncedSave();
                    }
                },
                deep: true
            }

        },
        created() {
            // Wait a have second between changes to actually call save
            this.debouncedSave = debounce(this.save, 1500);
        },
        methods: {
            setFocus(index, updateSelectionIndex, selectionIndex) {
                this.focusIndex = index;
                this.$nextTick(() => {
                    // since we have ref for the items in a for, it automatically adds them to an array, but the indexes don't always update properly,
                    // so we key them
                    const element = (index === this.newItemIndex) ? this.$refs["new-item-input"] : this.$refs["item-input-" + index];
                    element.focus();
                    if (updateSelectionIndex) {
                        element.selectionStart = selectionIndex || 0;
                        element.selectionEnd = selectionIndex || 0;
                    }
                });
            },
            incrementFocus() {
                this.setFocus(this.focusIndex + 1, true);
            },
            decrementFocus() {
                if (this.focusIndex > 0) {
                    this.setFocus(this.focusIndex - 1, true);
                }
            },
            addItem(index, setFocus) {
                this.groceryList.items.splice(index, 0, {
                    id: generateTemporaryId(),
                    name: "",
                    checked: false
                });
                if (setFocus) {
                    this.setFocus(index, true);
                }
            },
            deleteItem(item) {
                Arrays.remove(this.groceryList.items, item);
            },
            deleteListClick() {
                this.deleteList(this.groceryList);
                this.close();
            },
            save() {
                this.saveList(this.groceryList);
            }
        }
    }
</script>
<style scoped>
    input[readonly] {
        border: none;
    }

    input[type=checkbox] {
        /* Double-sized Checkboxes */
        -ms-transform: scale(1.5); /* IE */
        -moz-transform: scale(1.5); /* FF */
        -webkit-transform: scale(1.5); /* Safari and Chrome */
        -o-transform: scale(1.5); /* Opera */
        transform: scale(1.5);
        margin: 10px;
    }

    input.grocery-list-name {
        border: none;
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.125;
        margin-bottom: 5px;
    }

    input.grocery-list-name:focus {
        border: none;
        box-shadow: none;
    }

    .item-handle {
        cursor: grabbing;
    }
</style>
