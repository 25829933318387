import {defineStore} from "pinia";

import {UserService} from "@app/services/ApplicationProxy";

const userService = new UserService();

export const useUserStore = defineStore("user", {
    state() {
        return {
            authenticated: false,
            currentUser: null
        };
    },
    actions: {
        async fetchCurrentUser() {
            const user = await userService.me();
            this.updateCurrentUser(user);
            return user;
        },
        updateCurrentUser(user) {
            this.currentUser = user;
            this.authenticated = true;
        }
    }
});
