<template>
    <div>
        <section class="section">
            <div class="container">
                <nav class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <div class="control">
                                <a
                                    class="button is-link"
                                    @click="newRecipe"
                                >
                                    Create Recipe
                                </a>
                            </div>
                        </div>
                        <div class="level-item">
                            <div class="tabs is-boxed">
                                <ul>
                                    <li :class="{ 'is-active': selectedTab === TAB_SEARCH}">
                                        <a @click="setSelectedTab(TAB_SEARCH)">
                                            Search
                                        </a>
                                    </li>
                                    <li :class="{ 'is-active': selectedTab === TAB_RECENTLY_VIEWED}">
                                        <a @click="setSelectedTab(TAB_RECENTLY_VIEWED)">
                                            Recently Viewed
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <div v-show="selectedTab === TAB_SEARCH">
                    <div class="field has-addons">
                        <div class="control is-expanded has-icons-left">
                            <input
                                v-model="searchTerms"
                                class="input"
                                type="text"
                                placeholder="Search for a recipe"
                                @keyup.enter="fetchRecipes"
                            >
                            <span class="icon is-left">
                                <Icon icon="search" />
                            </span>
                        </div>
                        <div class="control">
                            <a
                                class="button"
                                @click="clearSearch"
                            >
                                <Icon
                                    icon="times"
                                    class="fa-lg"
                                />
                            </a>
                        </div>
                        <div class="control">
                            <a
                                class="button is-link"
                                @click="fetchRecipes"
                            >
                                Search
                            </a>
                        </div>
                    </div>
                    <div class="is-flex is-flex-direction-row">
                        <app-recipe-tag-selector
                            class="is-flex-grow-5"
                            @selected-tags-updated="selectedTagsUpdated"
                        >
                            <template #controls-left>
                                <div
                                    v-click-outside="hideTagFilterDropdown"
                                    class="dropdown is-right"
                                    :class="{ 'is-active': showTagFilterDropdown }"
                                >
                                    <div
                                        class="dropdown-trigger"
                                        @click="toggleTagFilterDropdown"
                                    >
                                        <button class="button">
                                            <span>{{ tagFilter.name }}</span>
                                            <span class="icon is-small">
                                                <Icon icon="angle-down" />
                                            </span>
                                        </button>
                                    </div>
                                    <div class="dropdown-menu">
                                        <div class="dropdown-content">
                                            <a
                                                v-for="option in tagFilterOptions"
                                                :key="option.value"
                                                href="#"
                                                class="dropdown-item"
                                                :class="{ 'is-active': tagFilter.value === option.value }"
                                                @click.prevent="selectTagFilter(option)"
                                            >
                                                {{ option.name }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template #controls-right>
                                <div
                                    v-click-outside="hideOwnershipFilterDropdown"
                                    class="dropdown is-right"
                                    :class="{ 'is-active': showOwnershipFilterDropdown }"
                                >
                                    <div
                                        class="dropdown-trigger"
                                        @click="toggleOwnershipFilterDropdown"
                                    >
                                        <button class="button">
                                            <span>{{ ownershipFilter.name }}</span>
                                            <span class="icon is-small">
                                                <Icon icon="angle-down" />
                                            </span>
                                        </button>
                                    </div>
                                    <div class="dropdown-menu">
                                        <div class="dropdown-content">
                                            <a
                                                v-for="option in ownershipFilterOptions"
                                                :key="option.value"
                                                href="#"
                                                class="dropdown-item"
                                                :class="{ 'is-active': ownershipFilter.value === option.value }"
                                                @click.prevent="selectOwnershipFilter(option)"
                                            >
                                                {{ option.name }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-click-outside="hideSortByDropdown"
                                    class="dropdown is-right"
                                    :class="{ 'is-active': showSortByDropdown }"
                                >
                                    <div
                                        class="dropdown-trigger"
                                        @click="toggleSortByDropdown"
                                    >
                                        <button class="button">
                                            <span>Sort by {{ sortBy.name }}</span>
                                            <span class="icon is-small">
                                                <Icon icon="angle-down" />
                                            </span>
                                        </button>
                                    </div>
                                    <div class="dropdown-menu">
                                        <div class="dropdown-content">
                                            <a
                                                v-for="sortOption in sortByOptions"
                                                :key="sortOption.property"
                                                href="#"
                                                class="dropdown-item"
                                                :class="{'is-active': sortOption.property === sortBy.property}"
                                                @click.prevent="selectSortBy(sortOption)"
                                            >
                                                {{ sortOption.name }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="control">
                                    <a
                                        class="button"
                                        @click.prevent="toggleSortDirection"
                                    >
                                        <Icon
                                            class="fa-lg"
                                            :icon="sortAscending ? 'sort-numeric-up' : 'sort-numeric-down'"
                                            :action="true"
                                        />
                                    </a>
                                </div>
                            </template>
                        </app-recipe-tag-selector>
                    </div>
                    <div class="pb-3" />
                    <div class="pb-3" />
                    <div
                        v-if="isSearching"
                        class="p-6"
                    >
                        <Loader
                            class="has-text-danger"
                            :size="15"
                        />
                    </div>
                    <app-recipe-columns
                        v-else
                        :recipes="recipes"
                    >
                        <template #no-recipes-exist>
                            <div class="notification is-info is-light">
                                <span v-if="hasSearch">No recipes matched your search!</span>
                                <span v-else>You don't have any recipes yet, click the "Create Recipe" button to create one!</span>
                            </div>
                        </template>
                    </app-recipe-columns>
                </div>
                <div v-show="selectedTab === TAB_RECENTLY_VIEWED">
                    <RecentlyViewedRecipes />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import debounce from "lodash/debounce";

    import system from "@app/services/System"
    import {isStringNullOrBlank} from "@app/services/Util.js";
    import {RecipeService} from "@app/services/ApplicationProxy.js";

    import Icon from "@app/components/Common/Icon.vue";
    import Loader from "@app/components/Common/Loader.vue";
    import RecipeColumns from "@app/components/Recipe/RecipeColumns.vue";
    import RecipeTagSelector from "@app/components/Recipe/RecipeTagSelector.vue";
    import RecentlyViewedRecipes from "@app/components/Recipe/RecentlyViewedRecipes.vue";
    import alertService from "@app/services/AlertService.js";

    const recipeService = new RecipeService();

    const SORT_ASCENDING_STORAGE_KEY = "home.sortAscending";
    const SORT_BY_STORAGE_KEY = "home.sortBy";
    const OWNERSHIP_FILTER_KEY = "home.ownershipFilter"

    const TAB_SEARCH = "search";
    const TAB_RECENTLY_VIEWED = "recently_viewed";

    export default {
        components: {
            Icon,
            Loader,
            "app-recipe-columns": RecipeColumns,
            "app-recipe-tag-selector": RecipeTagSelector,
            RecentlyViewedRecipes
        },
        data: function () {
            return {
                selectedTab: TAB_SEARCH,
                recipes: [],
                selectedTags: [],
                searchTerms: "",
                ownershipFilter: {
                    name: "All",
                    value: 2
                },
                sortBy: {
                    name: "Name",
                    property: "name"
                },
                tagFilter: {
                    name: "Any",
                    value: 0
                },
                sortAscending: true,
                showSortByDropdown: false,
                showOwnershipFilterDropdown: false,
                showTagFilterDropdown: false,
                ownershipFilterOptions: [
                    {
                        name: "Created by Me",
                        value: 0
                    },
                    {
                        name: "Shared with me",
                        value: 1
                    },
                    {
                        name: "All",
                        value: 2
                    }
                ],
                sortByOptions: [
                    {
                        name: "Name",
                        property: "name"
                    },
                    {
                        name: "Age",
                        property: "createDate"
                    },
                    {
                        name: "Rating",
                        property: "rating"
                    }
                ],
                tagFilterOptions: [
                    {
                        name: "Any",
                        value: 0
                    },
                    {
                        name: "All",
                        value: 1
                    }
                ],
                isSearching: false,

                // Add in these constants so we can use them in the template
                TAB_SEARCH: TAB_SEARCH,
                TAB_RECENTLY_VIEWED: TAB_RECENTLY_VIEWED
            }
        },
        computed: {
            searchParams() {
                return {
                    searchTerms: this.searchTerms,
                    selectedTags: this.selectedTags,
                    sortBy: this.sortBy.property,
                    sortAscending: this.sortAscending,
                    ownershipFilter: this.ownershipFilter.value,
                    tagFilter: this.tagFilter.value
                }
            },
            hasSearch() {
                return !isStringNullOrBlank(this.searchTerms);
            }
        },
        watch: {
            searchParams() {
                this.debouncedFetchRecipes();
            },
            sortAscending() {
                localStorage.setItem(SORT_ASCENDING_STORAGE_KEY, this.sortAscending);
            },
            sortBy() {
                if (this.sortBy) {
                    localStorage.setItem(SORT_BY_STORAGE_KEY, this.sortBy.name);
                }
                else {
                    localStorage.removeItem(SORT_BY_STORAGE_KEY);
                }
            },
            ownershipFilter() {
                if (this.ownershipFilter) {
                    localStorage.setItem(OWNERSHIP_FILTER_KEY, this.ownershipFilter.value);
                }
                else {
                    localStorage.removeItem(OWNERSHIP_FILTER_KEY);
                }
            }
        },
        created() {
            this.loadSortLocalStorage();
            this.fetchRecipes();

            system.setTitle("DasCookbook");

            this.debouncedFetchRecipes = debounce(this.fetchRecipes, 500);
        },
        methods: {
            fetchRecipes() {
                this.isSearching = true;
                recipeService.getAll(
                    this.searchParams.searchTerms,
                    this.searchParams.selectedTags,
                    this.searchParams.sortBy,
                    this.searchParams.sortAscending,
                    this.searchParams.ownershipFilter,
                    this.searchParams.tagFilter
                ).then(
                    recipes => {
                        this.recipes = recipes;
                    },
                    () => {
                        alertService.showErrorAlert("Failed to fetch recipes.");
                    }
                )
                    .then(() => {
                        this.isSearching = false;
                    });
            },
            newRecipe() {
                this.$router.push({
                    name: "CreateRecipe"
                });
            },
            selectedTagsUpdated(tags) {
                this.selectedTags = tags.map(t => t.key);
            },
            clearSearch() {
                this.searchTerms = "";
            },
            toggleSortDirection() {
                this.sortAscending = !this.sortAscending;
            },
            toggleOwnershipFilterDropdown() {
                this.showOwnershipFilterDropdown = !this.showOwnershipFilterDropdown;
            },
            toggleTagFilterDropdown() {
                this.showTagFilterDropdown = !this.showTagFilterDropdown;
            },
            toggleSortByDropdown() {
                this.showSortByDropdown = !this.showSortByDropdown
            },
            hideOwnershipFilterDropdown() {
                this.showOwnershipFilterDropdown = false;
            },
            hideSortByDropdown() {
                this.showSortByDropdown = false;
            },
            hideTagFilterDropdown() {
                this.showTagFilterDropdown = false;
            },
            selectOwnershipFilter(ownershipFilter) {
                this.ownershipFilter = ownershipFilter;
                this.hideOwnershipFilterDropdown();
            },
            selectTagFilter(tagFilter) {
                this.tagFilter = tagFilter;
                this.hideTagFilterDropdown();
            },
            selectSortBy(sortBy) {
                this.sortBy = sortBy;
                this.hideSortByDropdown();
            },
            loadSortLocalStorage() {
                let sortAscending = localStorage.getItem(SORT_ASCENDING_STORAGE_KEY);
                let sortByName = localStorage.getItem(SORT_BY_STORAGE_KEY);
                let ownershipFilter = localStorage.getItem(OWNERSHIP_FILTER_KEY);

                if (sortAscending !== null) {
                    this.sortAscending = sortAscending === "true";
                }

                if (sortByName) {
                    let sortBy = this.sortByOptions.find(sb => sb.name === sortByName)
                    if (sortBy) {
                        this.sortBy = sortBy;
                    }
                }

                if (ownershipFilter !== null) {
                    const filterNum = parseInt(ownershipFilter, 10);
                    const filter = this.ownershipFilterOptions.find(x => x.value === filterNum);
                    if (filter) {
                        this.ownershipFilter = filter;
                    }
                }
            },
            setSelectedTab(tab) {
                this.selectedTab = tab;
            }
        }
    }
</script>
