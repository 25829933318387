<template>
    <div class="container">
        <div
            v-if="userStats"
            class="box"
        >
            <div class="is-flex is-flex-direction-row">
                <span class="is-size-2 is-flex-grow-1">
                    Welcome {{ user.name }}!
                </span>

                <span class="is-size-4">
                    <a :href="accountManagementUrl">
                        Account Management
                        &nbsp;
                        <Icon
                            icon="external-link-alt"
                        />
                    </a>
                </span>
            </div>

            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Number of Recipes</label>
                </div>
                <div class="field-body">
                    <p class="control">
                        <span class="field-text">{{ userStats.numberOfRecipes }}</span>
                    </p>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Storage Used</label>
                </div>
                <div class="field-body">
                    <p class="control">
                        <span class="field-text">{{ $utils.prettyBytes(userStats.storageSpaceUsed) }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="box">
            <p class="is-size-2">
                Global  Recipe Permissions
            </p>
            <p>
                Users listed here will have the specified permission level to each of your recipes.
            </p>
            <EditGlobalPermissions />
        </div>
    </div>
</template>
<script>
    import {computed} from "vue";

    import {useUserStore} from "@app/stores/UserStore.js";
    import system from "@app/services/System"
    import {UserService} from "@app/services/ApplicationProxy";

    import EditGlobalPermissions from "@app/components/Recipe/Permissions/EditGlobalPermissions.vue";
    import Icon from "@app/components/Common/Icon.vue";
    import alertService from "@app/services/AlertService.js";

    const userService = new UserService();

    export default {
        components: {
            Icon,
            EditGlobalPermissions
        },
        setup() {
            const userStore = useUserStore();
            const user = computed(() => userStore.currentUser);

            return {
                userStore,
                user
            }
        },
        data: function() {
            return {
                userStats: null,
                accountManagementUrl: ""
            }
        },
        created() {
            this.accountManagementUrl = userService.getAccountManagementUrl(window.location);
            this.fetchUserStats();

            system.setTitle("Account | DasCookbook");
        },
        methods: {
            fetchUserStats() {
                userService.stats().then(
                    stats => {
                        this.userStats = stats;
                    },
                    () => {
                        alertService.showErrorAlert("Failed to fetch user statistics.");
                    }
                );
            }
        }
    }

</script>

<style scoped>

    .field-text {
        -moz-appearance:none;
        -webkit-appearance:none;
        align-items:center;
        border:1px solid transparent;
        border-radius:4px;
        box-shadow:none;
        display:inline-flex;
        font-size:1rem;
        height:2.5em;
        justify-content:flex-start;
        line-height:1.5;
        padding-bottom:calc(.5em - 1px);
        padding-left:calc(.75em - 1px);
        padding-right:calc(.75em - 1px);
        padding-top:calc(.5em - 1px);
        position:relative;
        vertical-align:top
    }
</style>
