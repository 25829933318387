<template>
    <app-modal
        ref="modal"
        title="Edit Ingredients"
        width="85%"
    >
        <p>
            Please enter the ingredients, one per line in the format: "amount unit name"
        </p>
        <div class="field pt-2">
            <div class="control">
                <textarea
                    v-model="ingredients"
                    class="textarea"
                    rows="20"
                />
            </div>
        </div>

        <template #footer-buttons>
            <button
                v-if="!processing"
                class="button is-link"
                type="button"
                @click="parse"
            >
                Done
            </button>
            <button
                v-if="processing"
                class="button is-link is-loading"
                type="button"
            >
                Parsing
            </button>
        </template>
    </app-modal>
</template>

<script>
    import {ParseRecipeService} from "@app/services/ApplicationProxy";
    import * as Utils from "@app/services/Util"

    import Modal from "@app/components/Common/Modal.vue";
    import alertService from "@app/services/AlertService.js";

    const parseRecipeService = new ParseRecipeService();

    export default {
        components: {
            "app-modal": Modal
        },
        props: {
            modelValue: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        emits: ["update:modelValue"],
        data() {
            return {
                ingredients: "",
                processing: false
            }
        },
        methods: {
            parse() {
                this.processing = true;
                parseRecipeService.parseIngredients(this.ingredients).then(
                    data => {
                        this.close();
                        this.$emit("update:modelValue", data.ingredientCategories);
                    },
                    () => {
                        this.processing = false;
                        alertService.showErrorAlert("Failed to parse ingredients.");
                    }
                )
            },
            convertIngredientsToText(ingredientCategories) {
                let items = [];

                for (let i = 0; i < ingredientCategories.length; i++) {
                    let category = ingredientCategories[i];
                    if (!Utils.isStringNullOrBlank(category.name)) {
                        // let's push an empty element as well, just to add some spacing between the categories
                        if (items.length > 0) {
                            items.push("");
                        }
                        items.push(`${category.name}:`)
                    }
                    items = items.concat(category.ingredients.map(Utils.prettyIngredient));
                }

                return items.join("\n");
            },
            clear() {
                this.ingredients = "";
                this.processing = false;
            },
            open() {
                this.clear();
                if (this.modelValue) {
                    this.ingredients = this.convertIngredientsToText(this.modelValue);
                }
                this.$refs.modal.open();
            },
            close() {
                this.$refs.modal.close();
            }
        }
    }
</script>
