<template>
    <div>
        <div class="field is-grouped is-grouped-multiline">
            <div
                v-for="tag in myTags"
                :key="tag.key"
                class="control"
            >
                <div
                    class="tags are-medium"
                    :class="{ 'has-addons': editable }"
                >
                    <span class="tag is-link">{{ tag.name }}</span>
                    <span
                        v-if="editable"
                        class="tag is-delete"
                        @click="removeTag(tag)"
                    />
                </div>
            </div>
            <span
                v-if="editable"
                class="has-text-centered is-size-5"
            >
                <app-icon
                    icon="plus"
                    :action="true"
                    @click="addTag"
                />
            </span>
        </div>
        <app-create-recipe-tag-modal
            ref="recipeTagModal"
            :recipe-id="recipeId"
            @new-tag="onTagAdded"
        />
    </div>
</template>

<script>

    import AppIcon from "@app/components/Common/Icon.vue";
    import CreateRecipeTagModal from "@app/components/Recipe/CreateRecipeTagModal.vue";

    import {RecipeService} from "@app/services/ApplicationProxy";
    import alertService from "@app/services/AlertService.js";

    const recipeService = new RecipeService();

    export default {
        components: {
            "app-icon": AppIcon,
            "app-create-recipe-tag-modal": CreateRecipeTagModal
        },
        props: {
            recipeId: {
                type: Number,
                required: true
            },
            tags: {
                type: Array,
                required: true
            },
            editable: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                myTags: []
            }
        },
        watch: {
            tags(newTags) {
                this.myTags = newTags;
            }
        },
        mounted() {
            this.myTags = this.tags;
        },
        methods: {
            addTag() {
                this.$refs.recipeTagModal.open();
            },
            onTagAdded(tag) {
                this.myTags.push(tag);
            },
            removeTag(tag) {
                recipeService.removeTag(this.recipeId, tag.key).then(
                    () => {
                        let ind = this.myTags.indexOf(tag);
                        this.myTags.splice(ind, 1);
                    },
                    () => {
                        alertService.showErrorAlert("Failed to remove tag.");
                    }
                )
            }
        }
    }

</script>
