<template>
    <div v-if="recipe !== null">
        <h1 class="title">
            {{ recipe.name }}
        </h1>
        <p class="is-size-4">
            {{ recipe.description }}
        </p>

        <nav class="level pt-5">
            <div class="level-left">
                <div
                    v-if="recipe.sourceUrl"
                    class="level-item pr-3"
                >
                    <div>
                        <p class="heading">
                            Source
                        </p>
                        <p class="title is-4">
                            <a
                                :href="recipe.sourceUrl"
                                target="_blank"
                            >
                                {{ recipe.sourceName || "Website" }}
                            </a>
                        </p>
                    </div>
                </div>

                <div
                    v-if="recipe.servings > 0"
                    class="level-item pr-3"
                >
                    <div>
                        <p class="heading">
                            Serves
                        </p>
                        <p>
                            <span class="title">{{ recipe.servings }}</span>
                            <span> {{ recipe.servingsUnit }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="level-right">
                <div
                    v-if="recipe.prepTime > 0"
                    class="level-item has-text-centered pr-3"
                >
                    <div>
                        <p class="heading">
                            Preparation Time
                        </p>
                        <p data-testid="preparation-time-value">
                            <span class="title">{{ $utils.formatDuration(prepTime, "m") }}</span>
                            <span> mins</span>
                        </p>
                    </div>
                </div>
                <div
                    v-if="recipe.cookTime > 0"
                    class="level-item has-text-centered pl-3 pr-3"
                >
                    <div>
                        <p class="heading">
                            Cook Time
                        </p>
                        <p data-testid="cook-time-value">
                            <span class="title">{{ $utils.formatDuration(cookTime, "m") }}</span>
                            <span> mins</span>
                        </p>
                    </div>
                </div>
                <div
                    v-if="totalTime.valueOf() > 0"
                    class="level-item has-text-centered pl-3 pr-3"
                >
                    <div>
                        <p class="heading">
                            Total Time
                        </p>
                        <p data-testid="total-time-value">
                            <span class="title">{{ $utils.formatDuration(totalTime, "m") }}</span>
                            <span> mins</span>
                        </p>
                    </div>
                </div>
            </div>
        </nav>

        <div
            v-if="recipe.featureImageUrl"
            class="box"
        >
            <figure class="feature-image-holder">
                <img
                    :src="recipe.featureImageUrl"
                    class="feature-image"
                    alt=""
                >
            </figure>
        </div>

        <div class="columns">
            <div
                data-testid="ingredients-container"
                class="column is-two-fifths"
            >
                <ul
                    v-for="category in recipe.ingredientCategories"
                    :key="category.name"
                >
                    <li
                        v-if="category.name"
                        class="pt-3"
                    >
                        <span class="is-size-4 has-text-weight-bold">
                            {{ category.name }}
                        </span>
                    </li>
                    <li
                        v-for="ingredient in category.ingredients"
                        :key="category.name + '_' + ingredient.name + '_' + ingredient.amount"
                        class="p-2 is-size-5"
                    >
                        <p>
                            <span v-if="editable">
                                <Icon
                                    icon="pencil-alt"
                                    :action="true"
                                    @click="openIngredientFeedback(ingredient)"
                                />
                            </span>
                            <span class="has-text-weight-bold">{{ $utils.prettyFraction(ingredient.amount) }}</span>
                            <span>{{ friendlyUnit(ingredient.unit, ingredient.amount) }}</span>
                            <span>{{ ingredient.name }}</span>
                        </p>
                    </li>
                </ul>
            </div>
            <div class="column">
                <ul
                    v-for="category in recipe.stepCategories"
                    :key="category.name"
                >
                    <li
                        v-if="category.name"
                        class="pt-3"
                    >
                        <span class="is-size-4 has-text-weight-bold">
                            {{ category.name }}
                        </span>
                    </li>
                    <li
                        v-for="step in category.steps"
                        :key="step.description"
                        class="p-2 is-size-5"
                    >
                        <p>
                            {{ step.description }}
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <IngredientFeedbackModal ref="ingredientFeedbackModal" />
    </div>
</template>

<script>
    import {Duration} from "luxon";

    import Icon from "@app/components/Common/Icon.vue";
    import IngredientFeedbackModal from "@app/components/Recipe/IngredientFeedbackModal.vue";
    import * as FriendlyConstants from "@app/services/FriendlyConstants.js";

    export default {
        components: {
            Icon,
            IngredientFeedbackModal
        },
        props: {
            recipe: {
                type: Object,
                required: true
            },
            editable: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            cookTime() {
                return Duration.fromObject({
                    minutes: this.recipe.cookTime
                });
            },
            prepTime() {
                return Duration.fromObject({
                    minutes: this.recipe.prepTime
                });
            },
            totalTime() {
                return Duration.fromObject({
                    minutes: this.recipe.cookTime + this.recipe.prepTime
                });

            }
        },
        methods: {
            openIngredientFeedback(ingredient) {
                this.$refs.ingredientFeedbackModal.open({
                    ingredient: ingredient,
                    recipeSourceUrl: this.recipe.sourceUrl
                });
            },
            friendlyUnit(value, amount) {
                if (amount <= 1) {
                    return FriendlyConstants.UNIT_SINGULAR[value] || "";
                }
                return FriendlyConstants.UNIT_PLURAL[value] || "";
            }
        }

    }
</script>

<style scoped>

    .feature-image-holder {
        display: block;
        height: 35em;
        line-height: 35em;
        overflow: hidden;
    }

    .feature-image {
        position: relative;
        margin: -50% auto;
        width: 100%;
        height: auto;
        vertical-align: middle;
    }

</style>
