const UNIT = {
    NONE: 1,
    TEASPOON: 2,
    TABLESPOON: 3,
    CUP: 4,
    POUND: 5,
    OUNCE: 6,

    // Metric :( ? :)?
    GRAM: 100,
    KILOGRAM: 101,
    LITER: 102,
    MILLILITER: 103

};

const UNITS = [
    UNIT.NONE,
    UNIT.TEASPOON,
    UNIT.TABLESPOON,
    UNIT.CUP,
    UNIT.POUND,
    UNIT.OUNCE,

    UNIT.GRAM,
    UNIT.KILOGRAM,
    UNIT.LITER,
    UNIT.MILLILITER
];

const RECIPE_PERMISSION_LEVEL = {
    NONE: 0,
    VIEWER: 100,
    EDITOR: 200,
    FULL_CONTROL: 300,
    OWNER: 1000
};

const RECIPE_PERMISSION_LEVELS = [
    RECIPE_PERMISSION_LEVEL.NONE,
    RECIPE_PERMISSION_LEVEL.VIEWER,
    RECIPE_PERMISSION_LEVEL.EDITOR,
    RECIPE_PERMISSION_LEVEL.FULL_CONTROL,
    RECIPE_PERMISSION_LEVEL.OWNER
];

const SELECTABLE_RECIPE_PERMISSION_LEVELS = [
    RECIPE_PERMISSION_LEVEL.VIEWER,
    RECIPE_PERMISSION_LEVEL.EDITOR,
    RECIPE_PERMISSION_LEVEL.FULL_CONTROL
];

const SORT_ORDER = {
    ASC: "ASC",
    DESC: "DESC"
};


const FILTER_OPERATION = {
    EQ: "eq",
    NE: "ne",
    LTE: "lte",
    GTE: "gte",
    LT: "lt",
    GT: "gt",
    CONT: "cont"
};

const FILTER_TYPE = {
    TEXT: "TEXT",
    DATE: "DATE",
    DROPDOWN: "DROPDOWN",
    DROPDOWN_COMPLEX: "DROPDOWN_COMPLEX"
};

const NUTRIENT_TYPE = {
    CALORIES: "CALORIES",
    TOTAL_FAT: "TOTAL_FAT",
    CHOLESTEROL: "CHOLESTEROL",
    SODIUM: "SODIUM",
    TOTAL_CARBOHYDRATES: "TOTAL_CARBOHYDRATES",
    FIBER: "FIBER",
    SUGARS: "SUGARS",
    PROTEIN: "PROTEIN"
};

// Recommended daily value of nurients in g or mg, depending on how they are shown in nutrition facts label
// Reference: https://www.fda.gov/food/new-nutrition-facts-label/daily-value-new-nutrition-and-supplement-facts-labels
const NUTRIENT_RECOMMENDED_DAILY_VALUE = {
    [NUTRIENT_TYPE.TOTAL_FAT]: 78,
    [NUTRIENT_TYPE.CHOLESTEROL]: 300,
    [NUTRIENT_TYPE.SODIUM]: 2300,
    [NUTRIENT_TYPE.TOTAL_CARBOHYDRATES]: 275,
    [NUTRIENT_TYPE.FIBER]: 28,
    [NUTRIENT_TYPE.PROTEIN]: 50
};

const USER_TIER = {
    FREE: 0,
    BASIC: 100,
    SUPER: 10_000
};

const ALERT_TYPE_ERROR = "ERROR";
const ALERT_TYPE_SUCCESS = "SUCCESS";
const ALERT_TYPE_WARN = "WARN";
const ALERT_TYPE_INFO = "INFO";

const EVENT_ALERT_SHOW = "alert:show";
const EVENT_ALERT_CLEAR = "alert:clear";

const EVENT_NAVIGATION_ACTIVE_CHANGED = "navigation:activeChanged";

// 5mb 1024*1024*5
const MAX_RECIPE_IMAGE_SIZE = 5242880;

export {
    UNIT,
    UNITS,

    RECIPE_PERMISSION_LEVEL,
    RECIPE_PERMISSION_LEVELS,
    SELECTABLE_RECIPE_PERMISSION_LEVELS,

    SORT_ORDER,
    FILTER_OPERATION,
    FILTER_TYPE,

    ALERT_TYPE_ERROR,
    ALERT_TYPE_SUCCESS,
    ALERT_TYPE_WARN,
    ALERT_TYPE_INFO,
    EVENT_ALERT_SHOW,
    EVENT_ALERT_CLEAR,

    EVENT_NAVIGATION_ACTIVE_CHANGED,

    MAX_RECIPE_IMAGE_SIZE,

    NUTRIENT_TYPE,
    NUTRIENT_RECOMMENDED_DAILY_VALUE,

    USER_TIER
}
