<template>
    <div>
        <h5 class="subtitle is-5">
            Granting {{ user.displayName }} access
        </h5>
        <RecipePermissionArticle
            :permission="recipePermission"
            :editable="true"
            @delete:permission="cancel()"
            @update:permission="({_, permissionLevel}) => permission = permissionLevel"
        />
    </div>
</template>

<script>
    import {RecipePermissionService} from "@app/services/ApplicationProxy.js";
    import alertService from "@app/services/AlertService.js";

    import RecipePermissionArticle from "@app/components/Recipe/Permissions/RecipePermissionArticle.vue";


    const recipePermissionService = new RecipePermissionService();

    export default {
        components: {
            RecipePermissionArticle
        },
        props: {
            user: {
                type: Object,
                required: true
            },
            recipeId: {
                type: Number,
                required: true
            }
        },
        emits: ["cancel"],
        data() {
            return {
                permission: null
            }
        },
        computed: {
            recipePermission() {
                return {
                    grantedUser: this.user,
                    permission: this.permission
                }
            }
        },
        methods: {
            grantPermission() {
                return recipePermissionService.grant(this.user.id, this.permission, this.recipeId).then(
                    () => {
                        this.clear();
                        return true;
                    },
                    () => {
                        alertService.showErrorAlert("Failed to grant" + this.user.displayName + " permissions");
                        return false;
                    }
                );
            },
            cancel() {
                this.clear();
                this.$emit("cancel")
            },
            clear() {
                this.permission = null;
            },
            isValid() {
                return this.permission !== null;
            }
        }
    }
</script>

<style scoped>

</style>
