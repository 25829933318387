<template>
    <div>
        <UserSearchDropdrown @user-selected="userSelected" />

        <h5 class="subtitle is-5">
            Current global permissions
        </h5>
        <div v-if="permissions.length > 0">
            <RecipePermissionArticle
                v-for="permission in permissions"
                :key="permission.grantedUser.id + '__' + permission.permission"
                :permission="permission"
                :editable="true"
                @update:permission="updatePermission"
                @delete:permission="deletePermission"
            />
        </div>
        <div v-else>
            <p>
                Currently there are no global permissions. Use the search box above to add share with other users.
            </p>
        </div>
    </div>
</template>

<script>
    import {RecipePermissionService} from "@app/services/ApplicationProxy.js";
    import alertService from "@app/services/AlertService.js";

    import RecipePermissionArticle from "@app/components/Recipe/Permissions/RecipePermissionArticle.vue";
    import UserSearchDropdrown from "@app/components/Common/UserSearchDropdrown.vue";

    const recipePermissionService = new RecipePermissionService();

    export default {
        components: {
            RecipePermissionArticle,
            UserSearchDropdrown
        },
        data() {
            return {
                permissions: []
            }
        },
        created() {
            this.fetchPermissions();
        },
        methods: {
            fetchPermissions() {
                recipePermissionService.getGlobal().then(
                    permissions => {
                        this.permissions = permissions
                    },
                    () => {
                        alertService.showErrorAlert("Unable to fetch global permissions.");
                    }
                )
            },
            clear() {
                this.permissions = [];
            },
            updatePermission({permission, permissionLevel}) {
                const oldPermission = permission.permission;
                permission.permission = permissionLevel;

                recipePermissionService.grant(permission.grantedUser.id, permissionLevel).then(
                    () => {},
                    () => {
                        // reset the permission on failure
                        permission.permission = oldPermission;
                        alertService.showErrorAlert("Failed to update recipe permissions for " + permission.grantedUser.displayName);
                    }
                );
            },
            deletePermission(permission) {
                recipePermissionService.revoke(permission.grantedUser.id).then(
                    () => {
                        this.$utils.Arrays.remove(this.permissions, permission);
                    },
                    () => {
                        alertService.showErrorAlert("Failed to remove permissions for " + permission.grantedUser.displayName);
                    }
                );
            },
            userSelected(user) {
                this.permissions.unshift({
                    grantedUser: user,
                    permission: null
                })
            }
        }
    }
</script>

<style scoped>

</style>
