<template>
    <app-modal
        ref="modal"
        title="Import Feature Image"
    >
        <div class="box has-background-grey-light is-flex is-align-items-center is-justify-content-center is-flex-direction-column file-upload-container">
            <input
                class="file-input"
                type="text"
                style="cursor: default;"
                autofocus
                @paste.prevent="pasted"
                @drop.prevent="dropped"
            >
            <span class="fa-5x">
                <app-icon icon="clipboard" />
            </span>
            <span>
                Paste the image or image URL here. Or drag an image here.
            </span>
        </div>

        <div
            class="box has-background-grey-light is-flex is-align-items-center is-justify-content-center
                    is-flex-direction-column file-upload-container"
        >
            <input
                class="file-input"
                type="file"
                style="cursor: pointer;"
                accept="image/*"
                @change="fileUploaded"
            >
            <span class="fa-5x">
                <app-icon icon="upload" />
            </span>
            <span>
                Click to upload an Image
            </span>
        </div>
    </app-modal>
</template>

<script>
    import * as Util from "@app/services/Util"

    import {MAX_RECIPE_IMAGE_SIZE} from "@app/services/Constants";

    import Modal from "@app/components/Common/Modal.vue"
    import Icon from "@app/components/Common/Icon.vue"
    import alertService from "@app/services/AlertService.js";

    export default {
        components: {
            "app-icon": Icon,
            "app-modal": Modal
        },
        emits: ["feature-image-added"],
        data: function() {
            return { }
        },
        methods: {
            pasted(event) {
                const pastedText = event.clipboardData.getData("text/plain");
                if (pastedText && Util.isValidURL(pastedText)) {
                    this.$emit("feature-image-added", {
                        url: pastedText
                    });
                    this.close();
                    return;
                }
                if (this.handleDataTransfer(event.clipboardData)) {
                    return;
                }
                alertService.showErrorAlert("Pasted contents is unsupported. Please paste either an image or URL");
            },
            // https://developer.mozilla.org/en-US/docs/We/home/mitchell/Desktop/Guacamole-LEAD-1-731x1024.jpgb/API/HTML_Drag_and_Drop_API/File_drag_and_drop
            dropped(event) {
                this.handleDataTransfer(event.dataTransfer);
            },
            fileUploaded(event) {
                let input = event.target;
                if (input.files.length > 0) {
                    this.processFile(input.files[0]);
                }
            },
            handleDataTransfer(dt) {
                let {items} = dt;
                // https://stackoverflow.com/questions/490908/paste-an-image-from-clipboard-using-javascript/4400761
                let files = []
                for (let index = 0; index < items.length; index++) {
                    var item = items[index];
                    if (item.kind === "file") {
                        files.push(item);
                    }
                }
                if (files.length === 1) {
                    this.processFile(files[0].getAsFile());
                    return true;
                }
                else if (files.length > 1) {
                    return false;
                }
                return false;
            },
            processFile(blob) {
                if (blob.size > MAX_RECIPE_IMAGE_SIZE) {
                    alertService.showErrorAlert("File is too big!")
                    return;
                }
                let reader = new FileReader();
                reader.onload = event => {
                    this.$emit("feature-image-added", {
                        bytes: event.target.result,
                        type: blob.type
                    });
                    this.close();
                };
                reader.readAsArrayBuffer(blob);
            },
            open() {
                this.$refs.modal.open();
            },
            close() {
                this.$refs.modal.close();
            }
        }

    }


</script>

<style scoped>
.file-upload-container {
    overflow: hidden;
    position:relative;
}
</style>
