<template>
    <div>
        <section class="section">
            <div class="container">
                <div class="columns">
                    <div class="column is-one-third">
                        <p>Please enter the food items to get nutrients of, one per  line in the format "amount unit name"</p>
                        <div class="field pt-2">
                            <div class="control">
                                <textarea
                                    v-model="state.searchFoodItems"
                                    class="textarea"
                                    rows="20"
                                />
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <button
                                    class="button is-primary"
                                    @click="search"
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="state.foodItems.length > 0"
                        class="column"
                    >
                        <NutritionFacts
                            :food="total"
                            class="is-pulled-right"
                        />

                        <table class="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>
                                        <span class="checkbox">
                                            <input
                                                v-model="allFoodsIncluded"
                                                type="checkbox"
                                            >
                                        </span>
                                    </th>
                                    <th />
                                    <th>Quantity</th>
                                    <th>Food</th>
                                    <th>Calories</th>
                                    <th>Weight</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(food, i) in state.foodItems"
                                    :key="i"
                                >
                                    <td>
                                        <span class="checkbox">
                                            <input
                                                v-model="food.includeInTotal"
                                                type="checkbox"
                                            >
                                        </span>
                                    </td>
                                    <td>
                                        <figure
                                            v-if="food.thumbnailUrl"
                                            class="image is-48x48"
                                        >
                                            <img :src="food.thumbnailUrl">
                                        </figure>
                                    </td>
                                    <td>{{ food.servingAmount }}{{ food.servingUnit }}</td>
                                    <td>{{ food.name }}</td>
                                    <td>{{ food.calories }}</td>
                                    <td>{{ food.servingInGrams }}g</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {NutrientService} from "@app/services/ApplicationProxy.js";
    import alertService from "@app/services/AlertService.js";


    const nutrientService = new NutrientService();


</script>

<script setup>
    import {reactive, computed} from "vue";
    import NutritionFacts from "@app/components/Nutrition/NutritionFacts.vue";

    const state = reactive({
        searchFoodItems: "200g chicken breast\n" +
            "80g corn\n" +
            "150g brown rice",
        foodItems: []
    });

    const total = computed(() => {
        let totalFood = {
            servingInGrams: 0,
            calories: 0,
            totalFat: 0,
            saturatedFat: 0,
            cholesterol: 0,
            sodium: 0,
            totalCarbohydrates: 0,
            fiber: 0,
            sugars: 0,
            protein: 0,
            potassium: 0
        };

        state.foodItems.forEach(item => {
            if (!item.includeInTotal) {
                return;
            }

            totalFood.servingInGrams += item.servingInGrams;
            totalFood.calories += item.calories;
            totalFood.totalFat += item.totalFat;
            totalFood.saturatedFat += item.saturatedFat;
            totalFood.cholesterol += item.cholesterol;
            totalFood.sodium += item.sodium;
            totalFood.totalCarbohydrates += item.totalCarbohydrates;
            totalFood.fiber += item.fiber;
            totalFood.sugars += item.sugars;
            totalFood.protein += item.protein;
            totalFood.potassium += item.potassium;
        })

        return totalFood;
    });

    const allFoodsIncluded = computed({
        get() {
            return state.foodItems.every(i => i.includeInTotal === true);
        },
        set(newValue) {
            state.foodItems.forEach(i => {
                i.includeInTotal = newValue;
            });
        }
    })

    function search() {
        const foodItemsRequest = state.searchFoodItems.split("\n").filter(i => i.length > 0);
        nutrientService.search(foodItemsRequest).then(
            resp => {
                state.foodItems = resp.foods;
                state.foodItems.forEach(i => {
                    i.includeInTotal = true;
                });
            },
            () => {
                alertService.showErrorAlert("Failed to search for nutrients");
            }
        );
    }

</script>

<style scoped>
    input[type=checkbox] {
        /* Double-sized Checkboxes */
        -ms-transform: scale(1.5); /* IE */
        -moz-transform: scale(1.5); /* FF */
        -webkit-transform: scale(1.5); /* Safari and Chrome */
        -o-transform: scale(1.5); /* Opera */
        transform: scale(1.5);
        margin: 10px;
    }
</style>
