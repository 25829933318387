<template>
    <app-modal
        ref="modal"
        title="Select Recipe"
        width="85%"
    >
        <div class="field has-addons">
            <div class="control is-expanded has-icons-left">
                <input
                    v-model="searchTerms"
                    class="input"
                    type="text"
                    placeholder="Search for a recipe"
                    @keyup.enter="fetchRecipes"
                >
                <span class="icon is-left">
                    <app-icon icon="search" />
                </span>
            </div>
            <div class="control">
                <a
                    class="button"
                    @click="clearSearch"
                >
                    <app-icon
                        icon="times"
                        class="fa-lg"
                    />
                </a>
            </div>
            <div class="control">
                <a
                    class="button is-link"
                    @click="fetchRecipes"
                >
                    Search
                </a>
            </div>
        </div>
        <app-recipe-columns :recipes="recipes">
            <template #recipe-card="slotProps">
                <app-recipe-card
                    :recipe="slotProps.recipe"
                    :on-click="selectRecipe"
                />
            </template>
        </app-recipe-columns>
    </app-modal>
</template>

<script>
    import debounce from "lodash/debounce";

    import {RecipeService} from "@app/services/ApplicationProxy.js";

    import Icon from "@app/components/Common/Icon.vue";
    import Modal from "@app/components/Common/Modal.vue";
    import RecipeCard from "@app/components/Recipe/RecipeCard.vue";
    import RecipeColumns from "@app/components/Recipe/RecipeColumns.vue";
    import {isStringNullOrBlank} from "@app/services/Util";
    import alertService from "@app/services/AlertService.js";

    const recipeService = new RecipeService();

    export default {
        components: {
            "app-icon": Icon,
            "app-modal": Modal,
            "app-recipe-card": RecipeCard,
            "app-recipe-columns": RecipeColumns
        },
        emits: ["recipe-selected"],
        data() {
            return {
                recipes: [],
                searchTerms: ""
            }
        },
        computed: {
            hasSearch() {
                return !isStringNullOrBlank(this.searchTerms);
            }
        },
        watch: {
            searchTerms() {
                // let's re-fectch the recipes as the search terms change
                this.debouncedFetchRecipes();
            }
        },
        created() {
            this.debouncedFetchRecipes = debounce(this.fetchRecipes, 500);
            this.debouncedFetchRecipes();
        },
        methods: {
            fetchRecipes() {
                recipeService.getAll(this.searchTerms, null, null, null, null).then(
                    recipes => {
                        this.recipes = recipes;
                    },
                    () => {
                        alertService.showErrorAlert("Failed to fetch recipes.");
                    }
                );
            },
            clearSearch() {
                this.searchTerms = "";
            },
            selectRecipe(recipe) {
                this.$emit("recipe-selected", recipe);
                this.close();
            },
            open() {
                this.$refs.modal.open();
            },
            close() {
                this.$refs.modal.close();
            }
        }
    }
</script>

<style scoped>

    .image {
        width: 450px;
        height: 450px;
        overflow: hidden;
    }

    .image img {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0 ;
        margin: auto auto;
    }

</style>
