<template>
    <div v-if="readonly">
        <p
            class="starability-result"
            :data-rating="rating"
        />
    </div>
    <div v-else>
        <fieldset class="starability-basic">
            <input
                id="no-rate"
                type="radio"
                class="input-no-rate"
                name="rating"
                value="0"
                :checked="rating === 0 || rating === null"
                aria-label="No rating."
            >
            <template
                v-for="n in 5"
                :key="uuid + '_' + n"
            >
                <input
                    :id="uuid + '_' + n"
                    type="radio"
                    name="rating"
                    :value="n"
                    :checked="rating === n"
                >
                <label
                    :for="uuid + '_' + n"
                    title="Terrible"
                    @click="select(n)"
                >
                    {{ n }} stars
                </label>
            </template>
        </fieldset>
    </div>
</template>

<script>
    import {v4 as UUID} from "uuid"

    export default {
        props: {
            readonly: {
                type: Boolean,
                default: false,
                required: false
            },
            modelValue: {
                type: Number,
                required: false,
                default: 0
            }
        },
        emits: ["update:modelValue"],
        data() {
            return {
                uuid: ""
            }
        },
        computed: {
            rating: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    console.log("Hi");
                    this.$emit("update:modelValue", value);
                }
            }
        },
        created() {
            this.uuid = UUID();
        },
        methods: {
            select(index) {
                this.rating = index;
            }
        }
    }
</script>

<style scoped>

</style>
