<template>
    <Modal
        ref="modal"
        :title="title"
        cancel-text="Close"
        :show-cancel-button="mode !== MODE_GRANT_USER"
    >
        <div v-if="mode === MODE_GRANT_USER">
            <GrantUserPermission
                ref="grantPermission"
                :recipe-id="recipe.recipeId"
                :user="selectedUser"
            />
        </div>
        <div v-else>
            <UserSearchDropdrown @user-selected="userSelected" />
            <EditRecipePermissions
                ref="permissionsList"
                class="pt-3"
                :recipe-id="recipe.recipeId"
            />
        </div>

        <template
            v-if="mode === MODE_GRANT_USER"
            #footer-buttons
        >
            <button
                class="button"
                type="button"
                @click="clear"
            >
                Cancel
            </button>
            <button
                class="button is-link"
                type="button"
                @click="grantPermission"
            >
                Grant
            </button>
        </template>
    </Modal>
</template>

<script>
    import Modal from "@app/components/Common/Modal.vue";
    import RecipePermissionPicker from "@app/components/Common/RecipePermissionPicker.vue";
    import UserSearchDropdrown from "@app/components/Common/UserSearchDropdrown.vue";

    import EditRecipePermissions from "@app/components/Recipe/Permissions/EditRecipePermissions.vue";
    import GrantUserPermission from "@app/components/Recipe/Permissions/GrantUserPermission.vue";

    const MODE_GRANT_USER = "MODE_GRANT_USER";
    const MODE_EDIT_EXISTING = "MODE_EDIT_EXISTING";

    export default {
        components: {
            Modal,
            RecipePermissionPicker,
            UserSearchDropdrown,
            EditRecipePermissions,
            GrantUserPermission
        },
        props: {
            recipe: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                mode: MODE_EDIT_EXISTING,
                selectedUser: null
            };
        },
        computed: {
            title() {
                return "Share " + this.recipe.name;
            }
        },
        created() {
            this.MODE_GRANT_USER = MODE_GRANT_USER;
            this.MODE_EDIT_EXISTING = MODE_EDIT_EXISTING;
        },
        methods: {
            open() {
                this.clear();
                this.$refs.modal.open();
            },
            close() {
                this.$refs.modal.close();
            },
            clear() {
                this.selectedUser = null;
                this.mode = MODE_EDIT_EXISTING;
            },
            grantPermission() {
                this.$refs.grantPermission.grantPermission().then(res => {
                    if (res) {
                        this.selectedUser = null;
                        this.mode = MODE_EDIT_EXISTING;
                    }
                })
            },
            userSelected(user) {
                this.selectedUser = user;
                this.mode = MODE_GRANT_USER;
            }
        }
    }

</script>
