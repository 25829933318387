<template>
    <div class="container">
        <div class="columns">
            <div class="column is-one-quarter">
                <div
                    v-if="!isEdit"
                    class="box"
                >
                    <h4 class="title is-4">
                        Import From
                    </h4>

                    <div class="field is-grouped is-grouped-centered">
                        <p class="control">
                            <button
                                class="button is-dark"
                                type="button"
                                @click="importWebsite"
                            >
                                Website
                            </button>
                        </p>
                    </div>
                </div>
                <div class="box">
                    <h4 class="title is-4">
                        <template v-if="isEdit">
                            Edit
                        </template>
                        <template v-else>
                            Manually Edit
                        </template>
                    </h4>

                    <div class="field">
                        <label class="label">Title</label>
                        <div class="control">
                            <input
                                v-model="name"
                                class="input"
                                type="text"
                                placeholder="Title"
                            >
                        </div>
                    </div>

                    <div class="field is-grouped is-grouped-centered">
                        <p class="control">
                            <button
                                class="button is-dark"
                                type="button"
                                @click="editIngredients"
                            >
                                Edit Ingredients
                            </button>
                        </p>
                    </div>

                    <div class="field is-grouped is-grouped-centered">
                        <p class="control">
                            <button
                                class="button is-dark"
                                type="button"
                                @click="editSteps"
                            >
                                Edit Steps
                            </button>
                        </p>
                    </div>

                    <div class="field">
                        <label class="label">Preparation Time (minutes)</label>
                        <div class="control has-icons-left">
                            <input
                                v-model="prepTime"
                                class="input"
                                type="number"
                                placeholder="600"
                            >
                            <span class="icon is-small is-left">
                                <app-icon icon="clock" />
                            </span>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Cook Time (minutes)</label>
                        <div class="control has-icons-left">
                            <input
                                v-model="cookTime"
                                class="input"
                                type="number"
                                placeholder="3600"
                            >
                            <span class="icon is-small is-left">
                                <app-icon icon="clock" />
                            </span>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Number of Servings</label>
                        <div class="control has-icons-left">
                            <input
                                v-model="servings"
                                class="input"
                                type="number"
                                placeholder="10"
                            >
                            <span class="icon is-small is-left">
                                <app-icon icon="utensils" />
                            </span>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Serving unit</label>
                        <div class="control has-icons-left">
                            <input
                                v-model="servingsUnit"
                                class="input"
                                type="text"
                                placeholder="pancakes"
                            >
                            <span class="icon is-small is-left">
                                <app-icon icon="utensils" />
                            </span>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Source</label>
                        <div class="control">
                            <input
                                v-model="sourceName"
                                class="input"
                                type="text"
                            >
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Source URL</label>
                        <div class="control">
                            <input
                                v-model="sourceUrl"
                                class="input"
                                type="text"
                            >
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Feature Image</label>
                        <div class="columns is-multiline is-vcentered feature-image-holder">
                            <div class="column is-half">
                                <figure
                                    :class="{ selected: selectedFeatureImage == null }"
                                    class="feature-image box has-background-grey-light image is-128x128 is-flex is-align-items-center is-justify-content-center"
                                    @click="selectFeatureImage(null)"
                                >
                                    No Image
                                </figure>
                            </div>
                            <div
                                v-for="image in possibleImages"
                                :key="image"
                                class="column is-half"
                            >
                                <figure
                                    class="image feature-image"
                                    :class="{ selected: selectedFeatureImage === image }"
                                    @click="selectFeatureImage(image)"
                                >
                                    <img
                                        :src="image.url"
                                        alt=""
                                    >
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div class="field is-grouped is-grouped-centered pt-3">
                        <p class="control">
                            <button
                                class="button is-dark"
                                type="button"
                                @click="addFeatureImageFromUrl"
                            >
                                Add Image
                            </button>
                        </p>
                    </div>

                    <div class="field">
                        <label class="label">Description</label>
                        <div class="control">
                            <textarea
                                v-model="description"
                                class="textarea"
                            />
                        </div>
                    </div>

                    <div class="field is-grouped is-grouped-centered">
                        <p class="control">
                            <button
                                class="button is-light"
                                type="button"
                                @click="cancel"
                            >
                                Cancel
                            </button>
                        </p>
                        <p class="control">
                            <button
                                class="button is-link"
                                type="button"
                                @click="save"
                            >
                                <template v-if="isEdit">
                                    Save
                                </template>
                                <template v-else>
                                    Create
                                </template>
                            </button>
                        </p>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="box">
                    <Recipe
                        :recipe="recipe"
                        :editable="true"
                    />
                </div>
            </div>
        </div>

        <EditStepsModal
            ref="editStepsModal"
            v-model="stepCategories"
        />
        <EditIngredientsModal
            ref="editIngredientsModal"
            v-model="ingredientCategories"
        />
        <ImportFeatureImageModal
            ref="importFeatureImageModal"
            @feature-image-added="featureImageAdded"
        />
        <ScrapeRecipeModal
            ref="scrapeRecipeModal"
            @recipe-scraped="update"
        />
        <LoadingModal
            ref="loadingModal"
            :message="loadingMessage"
        />
    </div>
</template>

<script>
    import system from "@app/services/System";

    import Icon from "@app/components/Common/Icon.vue";
    import EditStepsModal from "@app/components/Recipe/EditStepsModal.vue";
    import EditIngredientsModal from "@app/components/Recipe/EditIngredientsModal.vue";
    import ImportFeatureImageModal from "@app/components/Recipe/ImportFeatureImageModal.vue";
    import Recipe from "@app/components/Recipe/Recipe.vue";
    import ScrapeRecipeModal from "@app/components/Recipe/ScrapeRecipeModal.vue";
    import LoadingModal from "@app/components/Common/LoadingModal.vue";

    import {RecipeService} from "@app/services/ApplicationProxy";

    import {convertByteArrayToImage} from "@app/services/Util"
    import alertService from "@app/services/AlertService.js";

    const recipeService = new RecipeService();

    export default {
        components: {
            "app-icon": Icon,
            EditStepsModal,
            EditIngredientsModal,
            ImportFeatureImageModal,
            Recipe,
            ScrapeRecipeModal,
            LoadingModal
        },
        props: {
            recipeId: {
                type: Number,
                required: false,
                default: null
            }
        },
        data() {
            return {
                ourRecipeId: null,
                name: "",
                prepTime: 0,
                cookTime: 0,
                servings: 0,
                servingsUnit: "",
                sourceUrl: "",
                selectedFeatureImage: null,
                originalFeatureImage: null,
                sourceName: "",
                description: "",
                stepCategories: [],
                ingredientCategories: [],
                possibleImages: [],
                loadingMessage: ""
            }
        },
        computed: {
            recipe() {
                return {
                    name: this.name,
                    description: this.description,
                    prepTime: parseInt(this.prepTime || 0, 10),
                    cookTime: parseInt(this.cookTime || 0, 10),
                    servings: parseInt(this.servings || 0, 10),
                    servingsUnit: this.servingsUnit,
                    sourceUrl: this.sourceUrl,
                    featureImageUrl: (this.selectedFeatureImage || {url: null}).url,
                    sourceName: this.sourceName,
                    ingredientCategories: this.ingredientCategories,
                    stepCategories: this.stepCategories
                }
            },
            isEdit() {
                return this.ourRecipeId !== null;
            }
        },
        watch: {
            recipeId(newRecipeId) {
                // when the prop is mutated / update we want to fetch a new recipe
                this.ourRecipeId = newRecipeId;
                this.fetchRecipe();
            }
        },
        created() {
            this.clear();
            if (this.isEdit) {
                this.fetchRecipe();
                system.setTitle("Edit Recipe | DasCookbook");
            }
            else {
                system.setTitle("New Recipe | DasCookbook");
            }
        },
        methods: {
            clear() {
                this.ourRecipeId = this.recipeId;
                this.name = "";
                this.prepTime = 0
                this.cookTime = 0;
                this.servings = 0;
                this.servingsUnit = "";
                this.sourceUrl = "";
                this.sourceName = "";
                this.description = "";
                this.stepCategories = [];
                this.ingredientCategories = [];

                this.selectedFeatureImage = null;
                this.originalFeatureImage = this.selectedFeatureImage;
            },
            editSteps() {
                this.$refs.editStepsModal.open();
            },
            editIngredients() {
                this.$refs.editIngredientsModal.open();
            },
            importWebsite() {
                this.$refs.scrapeRecipeModal.open();
            },
            selectFeatureImage(image) {
                this.selectedFeatureImage = image;
            },
            addFeatureImageFromUrl() {
                this.$refs.importFeatureImageModal.open();
            },
            featureImageAdded(image) {
                if (!("url" in image)) {
                    image.url = convertByteArrayToImage(image.bytes, image.type);
                }
                this.possibleImages.push(image);
                this.selectedFeatureImage = image;
            },
            fetchRecipe() {
                recipeService.get(this.ourRecipeId).then(
                    data => {
                        this.update(data);
                    },
                    () => {
                        alertService.showErrorAlert("Failed to fetch recipe.");
                    }
                );
            },
            save() {
                this.$refs.loadingModal.open();
                this.saveRecipe()
                    .then(this.saveFeatureImage)
                    .then(() => {
                        this.$router.push({
                            name: "ViewRecipe",
                            params: {
                                recipeId: this.ourRecipeId
                            }
                        });
                    })
                    .catch(() => {
                        alertService.showErrorAlert("Failed to create recipe.");
                    })
                    .then(() => {
                        this.$refs.loadingModal.close();
                    });
            },
            saveRecipe() {
                let promise = null;
                if (this.isEdit) {
                    promise = recipeService.update(this.ourRecipeId, this.toViewModel());
                }
                else {
                    promise = recipeService.create(this.toViewModel());
                }
                this.loadingMessage = "Saving Recipe";
                return promise.then(
                    data => {
                        this.ourRecipeId = data.recipeId;
                        return Promise.resolve(true);
                    },
                    () => Promise.reject(new Error("Failed to save recipe"))
                )
            },
            saveFeatureImage() {
                let promise = null;
                if (this.originalFeatureImage !== this.selectedFeatureImage) {
                    if (this.selectedFeatureImage) {
                        if ("bytes" in this.selectedFeatureImage) {
                            promise = recipeService.uploadFeatureImage(
                                this.ourRecipeId,
                                this.selectedFeatureImage.bytes,
                                this.selectedFeatureImage.type
                            );
                        }
                        else {
                            promise = recipeService.updateFeatureImageUrl(this.ourRecipeId, this.selectedFeatureImage.url);
                        }
                    }
                    else {
                        promise = recipeService.removeFeatureImage(this.ourRecipeId);
                    }
                    this.loadingMessage = "Uploading Feature Image";
                    return promise.then(
                        () => Promise.resolve(true),
                        () => Promise.reject(new Error("Failed to save feature image"))
                    )
                }
                return Promise.resolve(false);
            },
            cancel() {
                // If we are in edit mode, then we go back to view, otherwise we go back home?
                if (this.isEdit) {
                    this.$router.push({
                        name: "ViewRecipe",
                        params: {
                            recipeId: this.ourRecipeId
                        }
                    });
                }
                else {
                    this.$router.push({
                        name: "Home"
                    });
                }
            },
            update(recipe) {
                this.name = recipe.name;
                this.description = recipe.description;
                this.prepTime = parseInt(recipe.prepTime || 0, 10);
                this.cookTime = parseInt(recipe.cookTime || 0, 10);
                this.servings = parseInt(recipe.servings || 0, 10);
                this.servingsUnit = recipe.servingsUnit;
                this.sourceUrl = recipe.sourceUrl;
                this.sourceName = recipe.sourceName;
                this.ingredientCategories = recipe.ingredientCategories;
                this.stepCategories = recipe.stepCategories;

                if (recipe.featureImageUrl) {
                    const featureImageObj = {
                        url: recipe.featureImageUrl
                    };
                    this.possibleImages = [featureImageObj];
                    this.selectedFeatureImage = featureImageObj;
                    this.originalFeatureImage = this.selectedFeatureImage;
                }
                else if (recipe.images) {
                    this.possibleImages = recipe.images.map(imageUrl => ({
                        url: imageUrl
                    }));
                    this.selectedFeatureImage = this.possibleImages[0];
                }
            },
            toViewModel() {
                return {
                    name: this.name,
                    description: this.description,
                    prepTime: parseInt(this.prepTime || 0, 10),
                    cookTime: parseInt(this.cookTime || 0, 10),
                    servings: parseInt(this.servings || 0, 10),
                    servingsUnit: this.servingsUnit,
                    sourceUrl: this.sourceUrl,
                    sourceName: this.sourceName,
                    ingredientCategories: this.ingredientCategories,
                    stepCategories: this.stepCategories
                }
            }
        }
    }

</script>

<style scoped>

.feature-image:hover {
    border: 0.35rem solid hsl(0, 0%, 29%);
    border-radius: 0.5rem;
    font-size: 0.9em;
    cursor: pointer;
}

.feature-image.selected {
    border: 0.35rem solid hsl(0, 0%, 21%);
    border-radius: 0.5rem;
    font-size: 0.9em;
}

.feature-image-holder {
    max-height: 500px;
    overflow-y: auto;
}

</style>
