import {DateTime, Duration} from "luxon";
import * as rf from "readable-fractions";
import prettyBytes from "pretty-bytes"
import {v4 as uuidv4} from "uuid";

import {UNIT} from "@app/services/Constants";
import {UNIT_SINGULAR, UNIT_PLURAL} from "@app/services/FriendlyConstants";

function getURLParameter(name, def) {
    if (typeof def === "undefined") {
        def = "";
    }
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results == null ? def : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function isStringNullOrBlank(string) {
    return (typeof string === "undefined" ||
        string === null ||
        typeof string !== "string" ||
        string.length === 0 ||
        string.trim().length === 0);
}

/**
 *  Formats a date into the given format. Assumes the date is a Luxon Date, if it is not a Luxon date it will attempt
 *      to convert it into a Luxon date, depending on whether it is a Number (milliseconds since epoch) or a native JS
 *      Date. Will throw an error if unable to convert.
 *
 * @param {Date|DateTime|Number} date The date to format.
 * @param {string} formatString The format to output the date in, defaults to yyyy-MM-dd HH:mm:ss
 * @returns {string} The formatted date, or empty string if date was null/undefined.
 */
function formatDateTime(date, formatString) {
    if (typeof date === "undefined" || null == date) {
        return "";
    }
    if (!DateTime.isDateTime(date)) {
        if (date instanceof Date) {
            date = DateTime.fromJSDate(date);
        }
        else if (date instanceof Number) {
            date = DateTime.fromMillis(date);
        }
        else {
            // Unable to determine the format of the date
            throw new Error("Unable to determine the type of this date!");
        }
    }

    if (typeof formatString === "undefined") {
        formatString = "yyyy-MM-dd HH:mm:ss";
    }

    return date.toFormat(formatString);
}

function formatDuration(duration, formatString) {
    if (typeof formatString === "undefined") {
        formatString = "hh:mm:ss";
    }
    if (duration && Duration.isDuration(duration)) {
        return duration.toFormat(formatString);
    }
    return "";
}

function round(num, places) {
    if (!places) {
        places = 2;
    }
    return parseFloat(num).toFixed(places);
}

function prettyNumber(num, places) {
    if (!places) {
        places = 2;
    }
    return parseFloat(num).toLocaleString(null, {
        minimumFractionDigits: places,
        maximumFractionDigits: places
    });
}

function scrollToTop() {
    window.scrollTo(0, 0);
}

/**
 * Converts the given number / fraction into a decimal. Supports the input already being a decimal
 *  or being a fraction.
 *
 * @param {any} val The value to convert to a decimal
 * @return {number} A number/decimal representing the given value
 */
function fractionToDecimal(val) {
    // Easiest to make the number a string at first to further processing
    val = String(val).trim();
    let res = Number(val);
    if (!isNaN(res)) {
        return res;
    }

    /*
     * We weren't able to parse it into a number, it likely has a fraction in it
     *  could also have a whole bunch of other crap in it though
     */
    return rf.fractionToDecimal(val);
}

function prettyFraction(val) {
    return rf.toReadableFraction(val, true);
}

/**
 *  Converts an ingredient to its pretty string
 * @param {ingredient} ingredient The ingredient to pretty print
 * @return {string} The string representing the ingredient
 */
function prettyIngredient(ingredient) {
    let parts = []
    if (ingredient.amount) {
        parts.push(prettyFraction(ingredient.amount))
    }
    if (ingredient.unit && ingredient.unit !== UNIT.NONE) {
        if (ingredient.amount <= 1) {
            parts.push(UNIT_SINGULAR[ingredient.unit])
        }
        else {
            parts.push(UNIT_PLURAL[ingredient.unit])
        }

    }
    if (ingredient.name) {
        parts.push(ingredient.name)
    }
    return parts.join(" ");
}

function isValidURL(str) {
    var a = document.createElement("a");
    a.href = str;
    return (a.host && a.host !== window.location.host);
}

function arrayBufferToBase64(arrayBuffer) {
    let binary = "";
    const bytes = new Uint8Array(arrayBuffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}

function convertByteArrayToImage(content, contentType) {
    const encodedContent = arrayBufferToBase64(content)
    return `data:${contentType};base64,${encodedContent}`;
}

const temporaryIdPrefix = "new_";

function generateTemporaryId() {
    return `${temporaryIdPrefix}${uuidv4()}`
}

function isTemporaryIdOrNull(id) {
    if (isStringNullOrBlank(id)) {
        return true;
    }
    return id.startsWith(temporaryIdPrefix);
}

class Arrays {

    static remove(array, element) {
        let ind = array.indexOf(element);
        if (ind >= 0) {
            array.splice(ind, 1);
        }
    }

    /**
     *  Replaces the given element in the list, with the new element. If given element wasn't found in the list, does nothing
     * @param array The array to modify
     * @param element The element to replace
     * @param newElement The new element to replace it with
     */
    static replace (array, element, newElement) {
        const ind = array.indexOf(element);
        if (ind >= 0) {
            array.splice(ind, 1, newElement);
        }
    }

    static add(array, element, index) {
        array.splice(index, 0, element);
    }

}

export {
    getURLParameter,
    isValidURL,
    isStringNullOrBlank,
    formatDateTime,
    formatDuration,
    round,
    prettyNumber,
    scrollToTop,
    fractionToDecimal,
    prettyFraction,
    prettyIngredient,
    prettyBytes,
    Arrays,
    convertByteArrayToImage,
    generateTemporaryId,
    isTemporaryIdOrNull
}
