<template>
    <input
        v-model="rawValue"
        class="input"
        type="text"
        placeholder="2"
    >
</template>

<script>
    import * as Util from "@app/services/Util";

    export default {
        props: {
            modelValue: {
                type: Number,
                required: true
            }
        },
        emits: ["update:modelValue"],
        data() {
            return {
                rawValue: 0
            }
        },
        watch: {
            modelValue(newVal) {
                if (Util.fractionToDecimal(this.rawValue) !== newVal) {
                    // Only set the value if the value is actually different
                    this.rawValue = Util.prettyFraction(newVal);
                }
            },
            rawValue(newVal) {
                let parsedVal = Util.fractionToDecimal(newVal);
                this.$emit("update:modelValue", parsedVal);
            }
        },
        created() {
            this.rawValue = Util.prettyFraction(this.modelValue);
        }
    }
</script>
