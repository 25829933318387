<template>
    <div v-if="recipe !== null">
        <div class="container pt-5">
            <div class="box">
                <div class="is-flex is-flex-direction-row">
                    <div class="is-flex-grow-1 pr-3 pt-2">
                        <app-recipe-tag-bar
                            :key="'recipe_tags_' + recipeId"
                            :recipe-id="recipeId"
                            :tags="recipe.tags"
                            :editable="true"
                        />
                    </div>
                    <div class="is-flex is-flex-direction-row">
                        <span class="is-size-3 pr-4">
                            <app-icon
                                icon="share-alt"
                                :action="true"
                                @click="openSharingModal"
                            />
                        </span>
                        <span class="is-size-3 pr-4">
                            <app-icon
                                icon="trash"
                                :action="true"
                                @click="deleteRecipe"
                            />
                        </span>
                        <span class="is-size-3 pr-4">
                            <app-icon
                                icon="pencil-alt"
                                :action="true"
                                @click="edit"
                            />
                        </span>
                        <div class="dropdown is-hoverable is-right">
                            <div class="dropdown-trigger">
                                <span class="is-size-3 pr-4">
                                    <app-icon
                                        icon="file-export"
                                        :action="true"
                                    />
                                </span>
                            </div>
                            <div class="dropdown-menu">
                                <div class="dropdown-content">
                                    <a
                                        href="#"
                                        class="dropdown-item"
                                        @click="exportRecipe('yaml')"
                                    >
                                        YAML
                                    </a>
                                    <a
                                        href="#"
                                        class="dropdown-item"
                                        @click="exportRecipe('json')"
                                    >
                                        JSON
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Rating
                    :model-value="recipe.rating"
                    @update:model-value="ratingUpdated"
                />

                <app-recipe
                    :key="'recipe_recipe_' + recipeId"
                    :recipe="recipe"
                />
            </div>
            <RelatedRecipeList
                :key="'recipe_related_' + recipeId"
                v-model="recipe.relatedRecipes"
                :recipe-id="recipeId"
            />
            <div class="pt-3" />
            <app-note-list
                :key="'recipe_notes_' + recipeId"
                :recipe-id="recipeId"
            />
            <RecipeSharingModal
                ref="recipeSharingModal"
                :recipe="recipe"
            />
        </div>
    </div>
</template>

<script>
    import debounce from "lodash/debounce";

    import {RecipeService} from "@app/services/ApplicationProxy";
    import Icon from "@app/components/Common/Icon.vue"
    import NoteList from "@app/components/Recipe/NoteList.vue"
    import RecipeTagBar from "@app/components/Recipe/RecipeTagBar.vue"
    import RelatedRecipeList from "@app/components/Recipe/RelatedRecipeList.vue";
    import Recipe from "@app/components/Recipe/Recipe.vue";
    import system from "@app/services/System";
    import alertService from "@app/services/AlertService.js";
    import {scrollToTop} from "@app/services/Util.js";
    import Rating from "@app/components/Common/Rating.vue";
    import RecipeSharingModal from "@app/components/Recipe/Permissions/RecipeSharingModal.vue";

    const recipeService = new RecipeService();

    export default {
        components: {
            "app-recipe": Recipe,
            "app-note-list": NoteList,
            RelatedRecipeList,
            "app-icon": Icon,
            "app-recipe-tag-bar": RecipeTagBar,
            Rating,
            RecipeSharingModal
        },
        props: {
            recipeId: {
                type: Number,
                required: true
            }
        },
        data: function () {
            return {
                recipe: null
            }
        },
        watch: {
            recipeId() {
                this.fetchRecipe();
                scrollToTop();
            }
        },
        created() {
            this.fetchRecipe();

            this.debouncedUpdateRating = debounce(this.updateRating, 500);
        },
        methods: {
            fetchRecipe() {
                recipeService.get(this.recipeId).then(
                    data => {
                        this.recipe = data;
                        system.setTitle(this.recipe.name + " | DasCookbook");
                        this.viewRecipe();
                    },
                    () => {
                        alertService.showErrorAlert("Failed to fetch recipe.");
                    }
                );
            },
            viewRecipe() {
                recipeService.view(this.recipeId);
            },
            clear() {
                this.recipe = null;
            },
            edit() {
                this.$router.push({
                    name: "EditRecipe",
                    params: {
                        recipeId: this.recipeId
                    }
                });
            },
            openSharingModal() {
                this.$refs.recipeSharingModal.open();
            },
            deleteRecipe() {
                recipeService.delete(this.recipeId).then(
                    () => {
                        this.$router.push({
                            name: "Home"
                        });
                    },
                    () => {
                        alertService.showErrorAlert("Failed to delete recipe.");
                    }
                )
            },
            exportRecipe(format) {
                if (!format) {
                    format = "yaml";
                }
                const exportUrl = recipeService.getExportUrl(this.recipeId, format);
                window.open(exportUrl, "_blank");
            },
            ratingUpdated(newRating) {
                console.log("rating has been updated");
                console.dir(newRating);
                this.recipe.rating = newRating;
                this.debouncedUpdateRating();
            },
            updateRating() {
                recipeService.rate(this.recipeId, this.recipe.rating).then(
                    () => {
                        alertService.showSuccessAlert("Successfully rated recipe!");
                    },
                    () => {
                        alertService.showErrorAlert("Failed to rate recipe!");
                    }
                )
            }
        }
    }
</script>

<style scoped>

    .dropdown-menu {
        min-width: 1rem;
    }

</style>
