import * as Constants from "@app/services/Constants.js"

const UNIT_SINGULAR = {
    [Constants.UNIT.NONE]: "",
    [Constants.UNIT.TEASPOON]: "teaspoon",
    [Constants.UNIT.TABLESPOON]: "tablespoon",
    [Constants.UNIT.CUP]: "cup",
    [Constants.UNIT.POUND]: "pound",
    [Constants.UNIT.OUNCE]: "ounce",

    [Constants.UNIT.GRAM]: "gram",
    [Constants.UNIT.KILOGRAM]: "kilogram",
    [Constants.UNIT.LITER]: "liter",
    [Constants.UNIT.MILLILITER]: "milliliter"
};

const UNIT_PLURAL = {
    [Constants.UNIT.NONE]: "",
    [Constants.UNIT.TEASPOON]: "teaspoons",
    [Constants.UNIT.TABLESPOON]: "tablespoons",
    [Constants.UNIT.CUP]: "cups",
    [Constants.UNIT.POUND]: "pounds",
    [Constants.UNIT.OUNCE]: "ounces",

    [Constants.UNIT.GRAM]: "grams",
    [Constants.UNIT.KILOGRAM]: "kilograms",
    [Constants.UNIT.LITER]: "liters",
    [Constants.UNIT.MILLILITER]: "milliliters"

}

const UNIT_ABV = {
    [Constants.UNIT.NONE]: "",
    [Constants.UNIT.TEASPOON]: "tsp",
    [Constants.UNIT.TABLESPOON]: "tbsp",
    [Constants.UNIT.CUP]: "cups",
    [Constants.UNIT.POUND]: "lbs",
    [Constants.UNIT.OUNCE]: "oz",

    [Constants.UNIT.GRAM]: "g",
    [Constants.UNIT.KILOGRAM]: "kg",
    [Constants.UNIT.LITER]: "l",
    [Constants.UNIT.MILLILITER]: "ml"

};

const RECIPE_PERMISSION_LEVEL = {
    [Constants.RECIPE_PERMISSION_LEVEL.NONE]: "None",
    [Constants.RECIPE_PERMISSION_LEVEL.VIEWER]: "Viewer",
    [Constants.RECIPE_PERMISSION_LEVEL.EDITOR]: "Editor",
    [Constants.RECIPE_PERMISSION_LEVEL.FULL_CONTROL]: "Full Control",
    [Constants.RECIPE_PERMISSION_LEVEL.OWNER]: "Owner"
};

export {
    UNIT_SINGULAR,
    UNIT_PLURAL,
    UNIT_ABV,

    RECIPE_PERMISSION_LEVEL
};
