<template>
    <div>
        <RecipeColumns :recipes="recipes">
            <template #no-recipes-exist>
                <span>You haven't viewed any recipes yet!</span>
            </template>
        </RecipeColumns>
    </div>
</template>

<script>
    import {RecipeService} from "@app/services/ApplicationProxy.js";
    import alertService from "@app/services/AlertService.js";

    import RecipeColumns from "@app/components/Recipe/RecipeColumns.vue";

    const recipeService = new RecipeService();

    export default {
        components: {
            RecipeColumns
        },
        data() {
            return {
                recipes: []
            }
        },
        created() {
            this.fetch();
        },
        methods: {
            fetch() {
                return recipeService.getRecentlyViewed(8).then(
                    recipes => {
                        this.recipes = recipes;
                    },
                    () => {
                        alertService.showErrorAlert("Failed to fetch recently viewed recipes.");
                    }
                )
            }
        }
    }
</script>

<style scoped>

</style>
