<template>
    <div class="container">
        <div class="title">
            <span>Related Recipes</span>
            <app-icon
                icon="plus"
                :action="true"
                @click="searchForRecipe"
            />
        </div>
        <app-recipe-columns :recipes="relatedRecipes">
            <template #recipe-card="slotProps">
                <app-recipe-card :recipe="slotProps.recipe">
                    <template #footer>
                        <router-link
                            :to="{ name: 'ViewRecipe', params: { recipeId: slotProps.recipe.recipeId } }"
                            class="card-footer-item"
                        >
                            View
                        </router-link>
                        <a
                            href="#"
                            class="card-footer-item"
                            @click.prevent="removeRelatedRecipe(slotProps.recipe)"
                        >
                            Un-relate
                        </a>
                    </template>
                </app-recipe-card>
            </template>
            <template #no-recipes-exist>
                <div class="notification is-info is-light">
                    <span>No related recipes.</span>
                </div>
            </template>
        </app-recipe-columns>

        <app-search-recipe-modal
            ref="searchRecipeModal"
            @recipe-selected="recipeSelected"
        />
    </div>
</template>

<script>

    import {RecipeService} from "@app/services/ApplicationProxy";
    import {Arrays} from "@app/services/Util";

    import Icon from "@app/components/Common/Icon.vue";
    import SearchRecipeModal from "@app/components/Recipe/SearchRecipeModal.vue";
    import RecipeCard from "@app/components/Recipe/RecipeCard.vue";
    import RecipeColumns from "@app/components/Recipe/RecipeColumns.vue";
    import alertService from "@app/services/AlertService.js";

    const recipeService = new RecipeService();

    export default {
        components: {
            "app-icon": Icon,
            "app-search-recipe-modal": SearchRecipeModal,
            "app-recipe-card": RecipeCard,
            "app-recipe-columns": RecipeColumns
        },
        props: {
            recipeId: {
                type: Number,
                required: true
            },
            modelValue: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        emits: ["update:modelValue"],
        computed: {
            relatedRecipes: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                }
            }
        },
        methods: {
            removeRelatedRecipe(relatedRecipe) {
                recipeService.removeRelatedRecipe(this.recipeId, relatedRecipe.recipeId).then(
                    () => {
                        Arrays.remove(this.relatedRecipes, relatedRecipe);
                    },
                    () => {
                        alertService.showErrorAlert("Failed to un relate recipe.");
                    }
                )
            },
            searchForRecipe() {
                this.$refs.searchRecipeModal.open();
            },
            recipeSelected(recipe) {
                recipeService.addRelatedRecipe(this.recipeId, recipe.recipeId).then(
                    () => {
                        this.relatedRecipes.push({
                            recipeId: recipe.recipeId,
                            name: recipe.name,
                            description: recipe.description,
                            featureImageUrl: recipe.featureImageUrl,
                            rating: recipe.featureImageUrl,
                            createDate: recipe.createDate,
                            modifiedDate: recipe.modifiedDate
                        });
                    },
                    () => {
                        alertService.showErrorAlert("Failed to add related recipe.");
                    }
                )
            }
        }
    }
</script>

<style scoped>

    .image {
        width: 450px;
        height: 450px;
        overflow: hidden;
    }

    .image img {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0 ;
        margin: auto auto;
    }

</style>
