import {toast} from "bulma-toast";

class AlertService {
    _showAlert(options) {
        let defaults = {
            dismissible: true,
            position: "top-right",
            pauseOnHover: true,
            closeOnClick: true,
            duration: 3 * 1000
        };
        toast({...defaults, ...options});
    }

    showInfoAlert(message, options) {
        let defaults = {
            message: message,
            type: "is-info"
        }
        this._showAlert({...defaults, ...options});
    }

    showSuccessAlert(message, options) {
        let defaults = {
            message: message,
            type: "is-success"
        }
        this._showAlert({...defaults, ...options});
    }

    showErrorAlert(message, options) {
        let defaults = {
            message: message,
            type: "is-danger"
        }
        this._showAlert({...defaults, ...options});
    }
}

export default new AlertService();
