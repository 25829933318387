<template>
    <div class="control has-icons-left">
        <div class="select">
            <select v-model="value">
                <option
                    v-for="option in options"
                    :key="option"
                    :value="option"
                >
                    {{ prettyOptions[option] || "" }}
                </option>
            </select>
        </div>
        <span class="icon is-small is-left">
            <app-icon icon="ruler-vertical" />
        </span>
    </div>
</template>

<script>
    import Icon from "@app/components/Common/Icon.vue"

    import {UNITS} from "@app/services/Constants";
    import * as FriendlyConstants from "@app/services/FriendlyConstants.js";

    export default {
        components: {
            "app-icon": Icon
        },
        props: {
            modelValue: {
                type: Number,
                required: false,
                default: () => 0
            }
        },
        emits: ["update:modelValue"],
        data() {
            return {
                options: UNITS,
                prettyOptions: FriendlyConstants.UNIT_ABV
            }
        },
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                }
            }
        },
        watch: {
            value (newValue) {
                this.$emit("update:modelValue", newValue);
            }
        }
    }

</script>
